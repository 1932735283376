import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
} from "@azure/msal-react";
import "./App.css";

import { Login } from "Pages/Login";


import { AdvancedTheme, App } from "@nokia-csf-uxr/ccfk";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
// import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { InteractionType } from "@azure/msal-browser";
// import GlobalCCFKStyles from '@nokia-csf-uxr/ccfk/styles/GlobalCCFKStyles';
import "@nokia-csf-uxr/nokia-design-system-tokens/css/_variables.css";
// import '@nokia-csf-uxr/nokia-design-system-tokens/global-style.css';

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./ErrorFallback";
import AppRouter from "./AppRouter";

const EnsoBp = () => {
  useMsalAuthentication(InteractionType.Redirect); //to auto redired login
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: { refetchOnWindowFocus: false },
    },
  });
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <AdvancedTheme
          advancedTheme={"CCFK FreeForm - Light"}
          addGlobalStyle={true}
          addGlobalVariables={true}
        >
          {/* ErrorBoundary */}
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <App disableAnimation={false}>
              <AuthenticatedTemplate>
                <AppRouter />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <Login />
              </UnauthenticatedTemplate>
            </App>
          </ErrorBoundary>
        </AdvancedTheme>
      </QueryClientProvider>
    </>
  );
};

export default EnsoBp;
