import React, { useEffect, useRef, useState } from "react";
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import { TextInputButton, TextInputLabelContent } from "@nokia-csf-uxr/ccfk/TextInput";
import CONFIG_API from "Constants/services/configurator";
import { setError, showBoqLoader } from "Store/general/auth";
import { DataGrid, HorizontalDivider, Skeleton, Tabs, TextInput, Typography, VerticalDivider, Checkbox, Label, Spinner } from '@nokia-csf-uxr/ccfk'
import FilterField, { FilterFieldIcon } from '@nokia-csf-uxr/ccfk/FilterField';
import { Tab } from '@nokia-csf-uxr/ccfk/Tabs'
import ExpansionPanelsContainer, {
    ExpansionPanel,
    ExpansionPanelTitle,
    ExpansionPanelHeader,
    ExpansionPanelBody,
    ExpansionPanelButton
} from '@nokia-csf-uxr/ccfk/ExpansionPanels';
import { roundCount } from 'Utils'
import { setProp } from "Store/Actions";
import { setQuantityList } from "Store/contentState/configurationArea/dynamicSlice";
import _ from 'lodash';
import './deviceaccessories.css';
import { ImageIcon, FileIcon, DeleteIcon } from '@nokia-csf-uxr/ccfk-assets/latest/';
import SelectFields from "./selectfield";
import SearchSearchIcon from '@nokia-csf-uxr/ccfk-assets/latest/SearchSearchIcon';

interface IProductCatalog {
    catalog: object,
    active_step: string,
    data_name: string,
    connectivity: string
}


const DeviceAccessories = (props: IProductCatalog) => {
    const dispatch = useDispatch();
    const configstepperSelector = useSelector((state: RootState) => state.config_stepper)
    const dynamicSelector = useSelector((state: RootState) => state.dynamic);
    const caseDetailsSelector = useSelector((state: RootState) => state.caseDetails);
    // const menus = _.map(keysMenu, type => ({
    //     id: type,
    //     value: type
    // }));
    // const menuid = menus && menus.length > 0 ? menus[0].id : '';
    // const [selectedTab, setSelectedTab] = useState('All');
    const selectedTab = "All";
    const catalogItems = _.cloneDeep(props.catalog[selectedTab]);
    // assign the categories uniq with alphabetical order asc
    const categoryOptions = _.map(catalogItems, (cat) => {
        return { "id": cat.product_id, "value": cat.product_id };
    });
    const keysCategory = _.uniqBy(categoryOptions, "id");
    const [searchQuery, setQuery] = useState('');
    const [expanded, setExpanded] = React.useState(undefined);
    const headerRef = React.useRef(null);
    let dataname = props.data_name;
    // const [selectedCategory, setselectedCategory] = useState('All');
    const sliceProperty = undefined;
    const allRefs = {};
    const placeholder = "Product Category";
    const inputRef = useRef(null);

    const getFilterProducts = (newStateProducts?) => {
        // if argument is sent then use that , else use the setProduct state data
        const catalogitems = newStateProducts ? newStateProducts : _.cloneDeep(products);
        if (searchQuery.length > 0 && catalogitems) {
            const lowerSearchquery = searchQuery.toLowerCase();
            // search text with either product code and description
            const res = catalogitems.filter((e) => (e.product_code.toLowerCase().includes(lowerSearchquery)) || (e.product_description.toLowerCase().includes(lowerSearchquery)))
            return res;
        }
        return catalogitems ? catalogitems : [];
    }

    const handleExpansion = id => (e) => {
        const newId = expanded === id ? undefined : id;
        if (e.type === 'keydown') {
            if (
                e.target.getAttribute('data-test') === 'header' && // check if keydown from header
                (e.key === ' ' || e.key === 'Enter')
            ) {
                setExpanded(newId);
            }
        }
        if (e.type === 'click') {
            setExpanded(newId);
        }
    };

    // const productsFiltered = getFilterProducts();
    const [products, setproducts] = useState(props.catalog[selectedTab]);

    if (configstepperSelector.data.active_step === 'review') {
        dataname = 'customise_offer';
    }
    const product_list = dynamicSelector.data.element[props.active_step] ? dynamicSelector.data.element[props.active_step][dataname] && dynamicSelector.data.element[props.active_step][dataname][selectedTab] : products;

    const data = [
        "category",
        {
            id: "category",
            label: "",
            required: false,
            description: null,
            help_title: null,
            help_text: "<Filter Product Based on Category",
            help_display: "element_after",
            readonly: false,
            disabled: false,
            default: "",
            options: keysCategory
        }
    ];

    const options = { 'category': keysCategory };

    useEffect(() => {
        let filteredItems;
        if (dynamicSelector.data.element[props.active_step]['category'] === '') {
            setproducts(props.catalog[selectedTab]);
            filteredItems = props.catalog[selectedTab];
        } else {
            const selectKey = { "key": dynamicSelector.data.element[props.active_step]['category'], "value": dynamicSelector.data.element[props.active_step]['category'] };
            /* argument - true is set to return back the filtered data without setProduct
            as setProduct will not update until render and cannot be accessed immediately will give
            older state. */
            filteredItems = filterItems(selectKey, true);
        }
        const productsFiltered = getFilterProducts(filteredItems);
        setproducts(productsFiltered);
    }, [dynamicSelector.data.element[props.active_step]['category'], searchQuery]);

    const updateCheckbox = (event, device_name, product, product_list) => {
        let productcheck = { ...product };
        productcheck.checked = event.target.checked ? true : false;
        let productChanges = product_list.map((item) => item.id === product.id ? productcheck : item);
        dispatch(setProp({ slice: "dynamic", key: `element.${props.active_step}.${[dataname]}.${[device_name]}`, value: productChanges }));
    }

    const selectedProduct = (device_name, product) => {
        let check = false
        if (dynamicSelector.data.element[props.active_step]) {
            if (dynamicSelector.data.element[props.active_step][dataname]) {
                if (dynamicSelector.data.element[props.active_step][dataname][device_name]) {

                    let checkProduct = dynamicSelector.data.element[props.active_step][dataname][device_name].find((item) => item.checked && item.id === product.id ? item : null)
                    check = checkProduct ? true : false
                }
            }
        }
        return check;
    }

    const getKeyvalueData = (product, value) => {
        let productcheckList = { ...product.data }
        productcheckList.quantity = value
        productcheckList.list_price = parseInt(value) * product.data.default_list_price
        dispatch(setQuantityList({ activeStep: props.active_step, useCase: dataname, selectName: selectedTab, device: productcheckList }));
    }

    const getSelectedCatalog = (selectTabs) => {
        let productList = [];
        if (dynamicSelector.data.element[props.active_step] && dynamicSelector.data.element[props.active_step][dataname] && dynamicSelector.data.element[props.active_step][dataname][selectTabs]) {
            productList = dynamicSelector.data.element[props.active_step][dataname][selectTabs].filter((item) => item.checked)
        }
        return productList
    }

    /** Function remove item from the datagrid */
    const removeItemFromSelectedList = (productId, selectTabs) => {
        const productList = dynamicSelector?.data?.element[props.active_step][dataname][selectTabs] ? [...dynamicSelector?.data?.element[props.active_step][dataname][selectTabs]] : [];
        const itemToBeRemoved = productList.filter(row => row.id == productId);
        if (itemToBeRemoved[0]) {
            const updatedProducts = productList.map((item) => item.id === productId ? { ...item, checked: false } : item)
            dispatch(setProp({ slice: "dynamic", key: `element.${props.active_step}.${[dataname]}.${[selectTabs]}`, value: updatedProducts }));
        }
    }

    const incrDecrValue = (device_name, product_id) => {
        let path = ''
        if (dynamicSelector.data.element[props.active_step]) {
            if (dynamicSelector.data.element[props.active_step][dataname]) {
                let index = dynamicSelector.data.element[props.active_step][dataname][device_name].findIndex(item => item.id === product_id)
                let indexData = dynamicSelector.data.element[props.active_step][dataname][device_name][index]
                if (indexData) {
                    path = dynamicSelector.data.element[props.active_step][dataname][device_name][index]['quantity']
                }
            }
        }
        return path
    }

    const getReturnValue = (device, product) => {
        let value = 1
        if (dynamicSelector.data.element[props.active_step]) {
            if (dynamicSelector.data.element[props.active_step][dataname] && dynamicSelector.data.element[props.active_step][dataname][device]) {
                let returnValue = dynamicSelector.data.element[props.active_step][dataname][device].find(item => item.id === product.data.id)
                if (returnValue) {
                    value = returnValue.quantity
                }
            }
        }
        return value
    }

    const loadKitContents = (rD) => {
        return (
            <ul style={{ width: '100%' }}>
                {
                    (rD.product_kits).map((kit, index) => (
                        <li style={{ listStyle: 'disc' }} key={index}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>
                                    aa{kit.product_kit_description}
                                    <br></br>{kit.product_kit_code}
                                </span>
                                <span style={{ marginLeft: '5%' }}>Quantity : {kit.product_kit_quantity}</span>
                            </div>
                        </li>
                    ))}
            </ul>
        )
    }

    const filterBasedOnCategory = (item, selectkey) => {
        filterItems(selectkey);
        dispatch(setProp({ slice: "dynamic", key: `element.${configstepperSelector.data.active_step}.${[item]}`, value: selectkey.value }));
    }

    const filterItems = (selectkey, returnFiltered?) => {
        const catprods = _.cloneDeep(props.catalog[selectedTab]);
        const filteredItems = _.filter(catprods, (cat) => cat.product_id === selectkey.key);
        if (returnFiltered) {
            return filteredItems;
        }
        setproducts(filteredItems);
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <div id="catalog-items" style={{ width: '45%' }}>
                    {/* dont show the tabs for all the modals */}
                    {/* {!_.includes(_.map(menus,'id'),'All') && <Tabs style={{ marginBottom: '20px', justifyContent: 'flex-start', borderBottom: '1px solid', borderColor: 'rgb(224 224 224)', marginTop: '3px' }} className="dialogTabs">
                        {
                            (menus.length === 0) ? (<Skeleton style={{ width: '350px' }} />) : (
                                <>
                                    {
                                        menus.map((type, i) => {
                                            return (<Tab key={type.id}

                                                onSelect={() => {
                                                    setSelectedTab(type.id);
                                                    setQuery("");
                                                }}
                                                selected={selectedTab === type.id}>{type.value}</Tab>)
                                        })
                                    }
                                </>
                            )

                        }


                    </Tabs>} */}
                    <SelectFields
                        optionsData={options}
                        active_step={configstepperSelector.data.active_step}
                        data={data}
                        updateDispatchStore={filterBasedOnCategory}
                        isDisabled={false}
                        ref={allRefs}
                        getReturnValue={getReturnValue}
                        dataStore={undefined}
                        sliceProperty={sliceProperty}
                        showdropdown={true}
                        placeholder={placeholder}
                        selectStyle={{ maxWidth: "100%", marginBottom: '3%' }}
                    />
                    {
                        (_.isEmpty(props.catalog))
                            ?
                            (<Skeleton style={{ width: '350px', textAlign: 'center', justifyContent: 'center', alignSelf: 'center' }} height="30px" />)
                            :
                            (
                                <>
                                    <div style={{ border: '1px solid', borderColor: 'rgb(224 224 224)' }}></div>
                                    {/* <div style={{display: 'inline-flex', width:'100%'}}>
                                    <div style={{width:'5%',marginTop:'1.5%'}}><SearchSearchIcon/></div>
                                    <TextInput placeholder='Search the products' onChange={(e) => setQuery(e.target.value)} value={searchQuery} style={{margin:'1% 0% 1% 0%',width:'95%'}}/> 
                                </div>
                                <div style={{ borderBottom: '1px solid', borderColor: 'rgb(224 224 224)' }}></div> */}
                                    <FilterField
                                        value={searchQuery}
                                        onChange={(e) => setQuery(e.target.value)}
                                        textInputProps={{
                                            placeholder: '  Search the products',
                                            showClearButton: true,
                                            clearButtonProps: {
                                                onClick: () => {
                                                    setQuery('');
                                                    inputRef.current && inputRef.current.focus()
                                                }
                                            },
                                            inputProps: {
                                                ref: inputRef,
                                                'aria-label': 'Search',
                                            }
                                        }}
                                        renderIcon={<FilterFieldIcon />}
                                        variant="outlined"
                                    />
                                </>)
                    }
                    <div id='tabContent'>
                        {
                            (_.isEmpty(props.catalog)) ?
                                (<Skeleton style={{ width: '500px', }} height="250px" icon={'Loading...'} />) :
                                (
                                    (<>
                                        {
                                            (products && products.length === 0) ? (<Typography>No Results found</Typography>) : (
                                                <div style={{ marginTop: '20px' }}>
                                                    <ExpansionPanelsContainer style={{ marginTop: '25px' }}>
                                                        {products && products.map(rD => (
                                                            <ExpansionPanel
                                                                expanded={expanded === rD.id}
                                                                key={rD.id}
                                                                id={rD.id}
                                                                hoverEffect
                                                                focusEffect
                                                                mountContent={false}
                                                            >
                                                                <ExpansionPanelHeader
                                                                    data-test="header"
                                                                    ref={headerRef}
                                                                    role="button"
                                                                    aria-expanded={expanded === rD.id}
                                                                    style={{ justifyContent: 'space-between' }}
                                                                >
                                                                    <div>
                                                                        <Checkbox
                                                                            checked={selectedProduct(selectedTab, rD)}
                                                                            disabled={false}
                                                                            onChange={(e) => {
                                                                                if (e.type === "change") {

                                                                                    updateCheckbox(e, selectedTab, rD, product_list)
                                                                                    return
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label>
                                                                            {rD.product_description}
                                                                            <br></br>
                                                                            <span style={{ fontSize: 'smaller', marginLeft: '10%' }}>{rD.product_code}</span>
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        rD && rD['product_kits'] ? (<ExpansionPanelButton
                                                                            onClick={handleExpansion(rD.id)}
                                                                            iconButtonProps={{
                                                                                'aria-hidden': true,
                                                                            }}
                                                                        />) : <></>
                                                                    }
                                                                    <div>
                                                                        <label>{caseDetailsSelector.data.currency} {rD.list_price}</label>
                                                                    </div>

                                                                </ExpansionPanelHeader>

                                                                <ExpansionPanelBody>
                                                                    <div style={{ display: 'flex', justifyContent: 'left', padding: '5%' }}>
                                                                        <div style={{ width: 'auto' }}>
                                                                            <p>{rD.title}</p>
                                                                            {
                                                                                rD ? (
                                                                                    rD['product_kits'] ? loadKitContents(rD) : []
                                                                                )
                                                                                    : <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </ExpansionPanelBody>
                                                            </ExpansionPanel>

                                                        ))}
                                                    </ExpansionPanelsContainer>
                                                </div>
                                            )
                                        }
                                    </>)

                                )
                        }

                    </div>
                </div>
                <VerticalDivider style={{ marginLeft: "1rem", marginRight: "1rem" }} />
                <div id="selected-items" style={{ flexGrow: '1' }}>
                    {

                        <>
                            <TextInputLabelContent>Selected Item</TextInputLabelContent>
                            <HorizontalDivider className='mb mt' />
                            <DataGrid
                                key={3}
                                //  modules={AllCommunityModules}

                                wrapperProps={{
                                    style: {
                                        height: '40vh',
                                        width: '100%'
                                    }
                                }}
                                rowData={getSelectedCatalog(selectedTab)}
                                defaultColDef={{
                                    editable: false,
                                    sortable: true,
                                    flex: 1,
                                    minWidth: 100,
                                    filter: true,
                                    resizable: true,
                                }}
                                columnDefs={[{ headerName: 'Products', field: 'product_description', },
                                {

                                    headerName: 'Quantity',
                                    field: 'quantity',
                                    cellRendererFramework: (params) => <div id={params.product_code}>
                                        <Spinner
                                            variant='outlined'
                                            value={getReturnValue(selectedTab, params)}
                                            id={params.product_code}
                                            min={1}
                                            max={100000}
                                            step={1}
                                            inputMode='numeric'
                                            incrementButtonProps={{ 'aria-label': `Value ${dynamicSelector.data.element[props.active_step] ? incrDecrValue(selectedTab, params.data.id) : 1}. Increment` }}
                                            decrementButtonProps={{ 'aria-label': `Value ${dynamicSelector.data.element[props.active_step] ? incrDecrValue(selectedTab, params.data.id) : 1}. Decrement` }}
                                            onChange={(e) => {
                                                getKeyvalueData(params, e.value)
                                            }}
                                        />
                                    </div>
                                },
                                { headerName: `Price (${caseDetailsSelector.data.currency})`, field: 'list_price', cellRendererFramework: (params) => roundCount(params.value) },
                                {
                                    headerName: "",
                                    cellRendererFramework: (params) => <DeleteIcon style={{ cursor: "pointer" }} onClick={() => removeItemFromSelectedList(params.data.id, selectedTab)} />
                                },

                                ]}
                                onGridReady={(params) => {
                                }}
                                isInlineRowActions={false}
                                isRowDrag={false} />

                        </>
                    }
                </div>

            </div>
        </>
    )
}

export default DeviceAccessories;