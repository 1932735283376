import { Button, HorizontalDivider, InlineFeedbackMessage, Skeleton, Tooltip, Typography } from "@nokia-csf-uxr/ccfk";
import List, { ListItemBasic, ListItemTextContainer } from "@nokia-csf-uxr/ccfk/List";
import CONFIG_API from "Constants/services/configurator";
import { setProp } from "Store/Actions";
import { setError } from "Store/general/auth";
import { RootState } from "Store/mainStore";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {ImageIcon, FileIcon, DeleteIcon} from '@nokia-csf-uxr/ccfk-assets/latest/';
import _ from 'lodash';
import JSZip from 'jszip';

const Planner = () => {
  const dispatch = useDispatch();
  const configstepperSelector = useSelector((state: RootState) => state.config_stepper);
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const findCaseSelector = useSelector((state: RootState) => state.findCase);
  const solutionCategory = configstepperSelector.data.solutionCategory;
  
  const [itemSelect, setSelectedItem] = useState({});
  const [plannerList, setSelectedPlanner] = useState(_.isEmpty(dynamicSelector.data.reference_solution.linkedPlanner) ? "": dynamicSelector.data.reference_solution.linkedPlanner['filename']);
  const [plannerLoading, setPlannerLoading] = useState(false);
  const [isPlannersFecthing, setIsPlannersFecthing] = useState(false);

  useEffect(() => {
    if(!dynamicSelector.data.reference_solution.ndacPlanner[configstepperSelector.data.active_step]) {
      fetchODTPlanners();
    }

    // Retain the ghighlighted item
    const linkedFile = _.isEmpty(dynamicSelector.data.reference_solution.linkedPlanner) ? "" : dynamicSelector.data.reference_solution.linkedPlanner['filename'];
    let selectSet = {};
    const fileIndex = dynamicSelector?.data?.reference_solution?.ndacPlanner?.[configstepperSelector.data.active_step] &&
    _.isArray(dynamicSelector?.data?.reference_solution?.ndacPlanner?.[configstepperSelector.data.active_step]) ? dynamicSelector.data.reference_solution.ndacPlanner[configstepperSelector.data.active_step].findIndex((row)=>row.file_name === linkedFile) : -1
    if(fileIndex !== -1){
      selectSet[fileIndex] = true;
      setSelectedItem(selectSet);
    }
    
  },[]);

  const fetchODTPlanners = async () => {
    // Set "19.XY.123456" as default sCRMID incase of empty selection
    const scrmid = (!findCaseSelector.data.sCRMID || findCaseSelector.data.sCRMID.length === 0) ? "19.XY.123456" : findCaseSelector.data.sCRMID;
    setIsPlannersFecthing(true)
    const resp = await CONFIG_API.getPlannerAttachments(scrmid);

    setIsPlannersFecthing(false)
    if (resp.status && resp.data) {
      dispatch(
        setProp({
          slice: "dynamic",
          key: `reference_solution.ndacPlanner.${configstepperSelector.data.active_step}`,
          value: resp.data.NetworkPlans
        })
      );
    } else {
        dispatch(
            setError({
                key: "error",
                value: {
                    errorCode: resp.errorCode,
                    errorMsg: resp.errorMessage,
                },
            })
        );

        dispatch(
          setProp({
            slice: "dynamic",
            key: `reference_solution.ndacPlanner.${configstepperSelector.data.active_step}`,
            value: 'No plans available for used sCRM id'
          })
        );
    }
  }

  // open the planner document in new tab
  const openPlanner = async (plannerinfo) => {
    const attachment_id = plannerinfo.attachment_ids;
    if(attachment_id) {
      setPlannerLoading(true);
      let encodedres = await fetchODTAttachment(attachment_id);
      await oldcode(encodedres.EncodedData);
    } else {
      dispatch(
        setError({
            key: "error",
            value: {
                errorCode: '500',
                errorMsg: 'Attachment is not present',
            },
        })
      );
    }
  }

  const oldcode = async(encodedres) => {
    const decodedData = atob(encodedres);
    // Convert binary data to Uint8Array
    const bytes = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; i++) {
      bytes[i] = decodedData.charCodeAt(i);
    }

    // Create Blob object from Uint8Array
    const blob = new Blob([bytes], { type: 'application/zip' });

    const unzippedFiles = await handleUnzip(blob);
    if(unzippedFiles && unzippedFiles.length > 0) {
      const filepath = unzippedFiles.find((obj) => obj.name === 'report.pdf');
      if(filepath) {
        const pdfBlob = new Blob([filepath.content], { type: 'application/pdf' });
        // Create a URL for the Blob content of the PDF file
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPlannerLoading(false);
        // Open the Blob URL in a new tab
        window.open(pdfUrl, '_blank');
        // Optionally, revoke the Blob URL after use to free up memory
        URL.revokeObjectURL(pdfUrl);
      } else {
        setPlannerLoading(false);
        dispatch(
          setError({
              key: "error",
              value: {
                  errorCode: '500',
                  errorMsg: 'Report.pdf is not present',
              },
          })
        );
      }
    } else {
      setPlannerLoading(false);
      dispatch(
        setError({
            key: "error",
            value: {
                errorCode: '500',
                errorMsg: 'Report.pdf is not present',
            },
        })
      );
    }
  }

  const handleUnzip = async (zippedFolder) => {
    try {
      const zip = new JSZip();
      const zipContents = await zip.loadAsync(zippedFolder);

      const files = [];
      for (const relativePath in zipContents.files) {
          const file = zipContents.files[relativePath];
          // Use await to ensure that each file is processed sequentially
          const contentdata = await file.async('blob');
          files.push({
            name: relativePath,
            content: contentdata,
          });
      }
      
      return files;
    } catch (error) {
      dispatch(
        setError({
            key: "error",
            value: {
                errorCode: '500',
                errorMsg: 'Error in Unzipping Planner attachment',
            },
        })
      );
    }
  };


  const fetchODTAttachment = async (attachment_id) => {
    const resp = await CONFIG_API.getZippedAttachment(attachment_id);
    if (resp.status && resp.data) {
      return resp.data;
    } else {
        dispatch(
            setError({
                key: "error",
                value: {
                    errorCode: resp.errorCode,
                    errorMsg: resp.errorMessage,
                },
            })
        );
    }
  }

  // link single planner selected and show it below
  const linkPlannerSelection = (keynumber,plannerData,event) => {
    event.preventDefault();
    event.stopPropagation();
    
    let selectSet = {};
    selectSet[keynumber] = true;
    setSelectedItem(selectSet);
    
  }

  // On Link Plan button click
  const handleLinkedPlannerClick = () => {
    const itemSelectedIndex = Object.keys(itemSelect)[0];
    const plannerData = dynamicSelector.data.reference_solution.ndacPlanner[configstepperSelector.data.active_step][itemSelectedIndex] ? dynamicSelector.data.reference_solution.ndacPlanner[configstepperSelector.data.active_step][itemSelectedIndex]: {};
    
    const plannerinfo = {
      filename : plannerData.file_name,
      sys_id : plannerData.sys_id
    };
    dispatch(
      setProp({
        slice: "dynamic",
        key: `reference_solution.linkedPlanner`,
        value: plannerinfo
      })
    );
    setSelectedPlanner(plannerData.file_name);
  }

  const deleteLinkedPlanner = () => {
    dispatch(
      setProp({
        slice: "dynamic",
        key: `reference_solution.linkedPlanner`,
        value: {}
      })
    );
    setSelectedItem({});
    setSelectedPlanner("");
    setSelectedItem({});
  }

  /** Get Link This Plan button enable / disable status */
  const isLinkButtonDiablled = () => {
    return !(_.isEmpty(dynamicSelector.data.reference_solution.linkedPlanner) 
    && !_.isEmpty(dynamicSelector.data.reference_solution.ndacPlanner[configstepperSelector.data.active_step]))
    || Object.keys(itemSelect).length === 0;
  }

  const isPlanLinked = () => {
    if(_.isEmpty(dynamicSelector.data.reference_solution.ndacPlanner[configstepperSelector.data.active_step])){
      return true;
    }
    const linkedPlanner = Object.keys(dynamicSelector.data.reference_solution.linkedPlanner)
    return linkedPlanner.length > 0 ;
  }

  /** Get the status whether linked plan is removable or not */
  const isPlannerDeleteEnabled = () =>{
    let isEnabled = true;
    if(solutionCategory === "NDAC" && configstepperSelector.data.active_step === "connectivity"){
        // Check atleast one item has selected from the add to catalog popup and added in BoQ
        let connectivity_ndac_items = {};
        if(dynamicSelector.data.element && dynamicSelector.data.element[configstepperSelector.data.active_step]){
          connectivity_ndac_items = dynamicSelector.data.element[configstepperSelector.data.active_step]['connectivity_ndac_items'];
          connectivity_ndac_items = connectivity_ndac_items ? connectivity_ndac_items : dynamicSelector.data.element[configstepperSelector.data.active_step]['compact_kit'];
        }
        const BoQ = dynamicSelector.data.boq && dynamicSelector.data.boq.length > 0 ? dynamicSelector.data.boq : [];
        const productIds = BoQ.map(row=>row.product_id);
        const ndacItemsKeys = connectivity_ndac_items ? Object.keys(connectivity_ndac_items) : [];
        let isItemAdded = false;
        ndacItemsKeys.forEach(key=>{
            isItemAdded = isItemAdded || connectivity_ndac_items[key].some(item=>productIds.includes(item.product_code));
        });
        isEnabled = !isItemAdded
    }
    return isEnabled;
  }
  
  return (
    <>
      <InlineFeedbackMessage show={plannerLoading} variant="warning">
          {
              //@ts-ignore
              <Typography>{'Loading Planner Attachment , please wait... '}</Typography>
          }
      </InlineFeedbackMessage>
      {
        !isPlanLinked() && (
          <InlineFeedbackMessage show={true} variant="warning">
          {
              <Typography>{'Select and link the planner '}</Typography>
          }
          </InlineFeedbackMessage>
        )
      }
      {
        (!isPlannersFecthing && _.isEmpty(dynamicSelector.data.reference_solution.ndacPlanner[configstepperSelector.data.active_step])) && (
          <InlineFeedbackMessage show={true} variant="warning">
            {
                <Typography>{'There are no Network Plans available for entered sCRM ID'}</Typography>
            }
        </InlineFeedbackMessage>
        )
      }
      <div id="listPlanners" style={{marginTop:'10px'}}>
      {
        (dynamicSelector.data?.reference_solution?.ndacPlanner[configstepperSelector.data.active_step]) ?
          typeof(dynamicSelector.data?.reference_solution?.ndacPlanner[configstepperSelector.data.active_step]) === 'string' ? <>{dynamicSelector.data?.reference_solution?.ndacPlanner[configstepperSelector.data.active_step]}</>
          :
          (<List hoverEffect={false} elevationProps={{ elevationIndex: 0 }}>
            <Typography style={{ flex: "initial" }}>{"Planner Documents"}</Typography>
            <HorizontalDivider className='mb' />
            {dynamicSelector.data.reference_solution.ndacPlanner[configstepperSelector.data.active_step].map((rD, i) => {
              const selectedHiglight = itemSelect[i]  ? itemSelect[i] : false;
              const filename = (rD.file_name).replace('.zip','');
              return (
                <>
                  <ListItemBasic
                    key={i} 
                    style={{ justifyContent: 'space-between' }} 
                    onClick={(event) => {
                      linkPlannerSelection(i,rD,event)
                    }}
                    selected={selectedHiglight}>
                    <ListItemTextContainer className='mb' 
                      style={{ display: 'flex',
                      paddingTop:'1rem',
                      width: '100%',
                      flexFlow: 'row',
                      justifyContent: 'space-between'}}>
                      <div style={{ display: 'flex',flexFlow: 'row'}}>
                        <FileIcon/> 
                        <span style={{lineHeight: "30px",padding: "0 5px"}}>{`${filename}`}</span>
                      </div>
                      <Tooltip tooltip="Open Planner" placement="bottom">                     
                      <div style={plannerLoading ? { display: 'flex',flexFlow: 'row',pointerEvents:'none', color:'#ccc'} : { display: 'flex',flexFlow: 'row', cursor:"pointer"}}>
                        <span style={{ padding: '3px'}}>View</span>
                        <ImageIcon onClick={() => openPlanner(rD)} color={plannerLoading ? '#ccc' : '#757575'}/>
                      </div>
                      </Tooltip>
                    </ListItemTextContainer>
                  </ListItemBasic>
                </>
              )
            })}
          </List>) 
          : (<></>)
      }
      {
        isPlannersFecthing && (<Skeleton style={{ width: '250px' }} />)
      }
      </div>

      <div 
        id="buttonLink" 
        style={{
          padding:'5px 0',
          marginTop:'5%',
          width:'max-content',
          marginLeft:'2%'}}
      >
        <Button 
          disabled={isLinkButtonDiablled()} 
          onClick={handleLinkedPlannerClick}>
            Link This Plan
        </Button>        
      </div>

      {
        plannerList && (
          <div id="linkedPlanners" style={{
            marginTop:'1rem',
            marginLeft:'2%',
            display:"flex",
            width: '94%',
            flexFlow: 'row',
            justifyContent: 'space-between'}}>
              <div style={{ display: 'flex',flexFlow: 'row'}}>
                <FileIcon/> 
                <span style={{
                  padding: "3px 5px",
                  color: 'var(--g-color-global-secondary-content)',
                  font: 'var(--ff-typography-body)'
                }}>
                  {`${plannerList}`}
                </span>
              </div>
              {
                isPlannerDeleteEnabled() && (
                  <div style={{ display: 'flex',flexFlow: 'row', padding: '2px 0',cursor:'pointer'}}>
                    <Tooltip
                      tooltip={`Delete ${plannerList} from the list`}
                      placement="bottom"
                    >
                    <DeleteIcon onClick={() => deleteLinkedPlanner()}/>
                    </Tooltip>
                  </div>
                )
              }
              
          </div>  
        )
      }
     
    </>
  )
}

export default React.memo(Planner);