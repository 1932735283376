import React from "react";
import OptionsFrame from "Components/Content/Frames/OptionsFrame";
import ViewFrame from "Components/Content/Frames/ViewFrame";
import Options from "Components/Content/configurationArea/Business/Options";
import View from "Components/Content/configurationArea/Business/View";
import Frame from "Components/Content/Frames/Frame";
import { useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import FullSolutionsView from "./configurator_solutions/FullSolutionsView";
import _ from "lodash";

const BusinessIndex = () => {
  const fullSolution = useSelector(
    (state: RootState) => state.business.data.options.PTDAC.caseIndustry
  );
  const tabSelect = useSelector(
    (state: RootState) => state.dynamic.data.tabSelect
  );

  return (
    <>
      <Frame>
        <OptionsFrame title="Segments & Business  Inputs">
          <Options />
        </OptionsFrame>
        <ViewFrame>
          {/* {((fullSolution === 'reference_solution' || fullSolution === 'mining') && tabSelect === 'Full Solutions'? <FullSolutions/> :  fullSolution != "" ? <View /> : <></>)} */}
          {(_.includes(["reference_solution","manufacturing","mining","port"],fullSolution)) &&
          tabSelect === "Full Solutions" ? (
            <FullSolutionsView />
          ) : (
            <></>
          )}
        </ViewFrame>
      </Frame>
    </>
  );
};

export default BusinessIndex;
