import { App } from '@nokia-csf-uxr/ccfk'
import { EmptyState } from 'Components/Content/EmptyState'
import Header from 'Components/Header'
import React from 'react'

function getLandingPageUrl() {
  // Get the configured path from the environment variable
  const configPath = process.env.REACT_APP_LANDING_PAGE?.trim() || '/';

  // Check if configPath is an absolute URL (starts with "http" or "https")
  if (configPath.startsWith('http://') || configPath.startsWith('https://')) {
    return configPath; // Use as-is if it's an absolute URL
  }

  // Handle cases where it's empty, whitespace, or a relative path
  if (!configPath || configPath === '/') {
    return window.location.origin; // Default to the root URL
  }

  // For relative paths like "/some-route", ensure it's correctly appended to the origin
  return `${window.location.origin}${configPath.startsWith('/') ? '' : '/'}${configPath}`;
}

const NotFound = () => {
  return (
    <>
      <App disableAnimation={false}>
        <Header />
        <div
          style={{ height: "100vh",padding: "5rem 0", display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <EmptyState
            title="Page not found"
            msg={
              "Sorry, the page you are looking for cannot found."
            }
            src="/assets/images/page-not-found.svg"
            actionText="Go to landing page"
            actionhref={() => window.location.href = getLandingPageUrl()}
          />
        </div>
      </App>
    </>
  )
}

export default NotFound