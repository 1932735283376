import React, { useEffect, useState } from "react";
import Typography from "@nokia-csf-uxr/ccfk/Typography";
import Tabs, { Tab, TabsContent } from "@nokia-csf-uxr/ccfk/Tabs";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { setProp } from "Store/Actions";
import { RootState } from "Store/mainStore";
const ARIA_LABEL_TEXT_1 = "To select Tab ";
const ARIA_LABEL_TEXT_2 = " press spacebar";

const TabPanel = ({ index, selected, children, ...otherProps }) => {
  return (
    <div hidden={index !== selected} {...otherProps}>
      {children}
    </div>
  );
};

const TabsDemo = (props) => {
  const [selected, setSelected] = useState(
    props.tabSelected && props.tabSelected.toString().length > 0
      ? props.tabSelected
      : 0
  );
  const businessSelector = useSelector((state: RootState) => state.business);

  // dynamically accessing label sent via props
  const TAB_DATA = props.config;
  const dispatch = useDispatch();
  // Component effect
  useEffect(() => {
    const tabSelected = props.config.find((data) => data.tab.index === selected);
    const label = tabSelected?.tab?.label;
    if (businessSelector.data.options.PTDAC.caseIndustry === "reference_solution" && label) {      
      dispatch(setProp({ slice: "dynamic", key: "tabSelect", value: label }));
    }
  }, [props.config, selected]);

  /** clickHandler */
  const clickHandler = (selected) => () => {
    setSelected(selected);
  };

  return (
    <>
      <Tabs alignment="left" fullWidth={false} variant="default">
        {TAB_DATA.map(({ tab }, i) => {
          const ariaMainText = tab && tab.label;
          return (
            <Tab
              id={`tab-${i}`}
              aria-label={
                selected === i
                  ? `${ariaMainText}`
                  : `${ARIA_LABEL_TEXT_1} ${ariaMainText} ${ARIA_LABEL_TEXT_2} `
              }
              aria-controls={`tab-content-${i}`}
              aria-posinset={tab && tab.label ? i + 1 : undefined}
              aria-setsize={tab && tab.label ? TAB_DATA.length : undefined}
              role="option"
              key={`tab-${i}`}
              selected={selected === i}
              onSelect={clickHandler(i)}
              {...tab}
            >
              {tab.children}
            </Tab>
          );
        })}
      </Tabs>
      <TabsContent selected={selected}>
        {TAB_DATA.map(({}, i) => {
          const activetab = props.config.find((x) => x.tab.index === i);
          const content = activetab.tab.content;
          return (
            <TabPanel
              id={`tab-content-${i}`}
              key={i}
              index={i}
              selected={selected}
              role="tabpanel"
              aria-labelledby={`tab-${i}`}
              aria-live="polite"
              aria-relevant="all"
            >
              <Typography typography="BODY">
                {content}
              </Typography>
            </TabPanel>
          );
        })}
      </TabsContent>
    </>
  );
};
export default TabsDemo;
TabsDemo.propTypes = {
  config: PropTypes.array,
  tabSelected: PropTypes.number,
};

TabPanel.propTypes = {
  index: PropTypes.number,
  selected: PropTypes.number,
  children: PropTypes.object,
};
