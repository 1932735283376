/**
 * Custom hook that provides utility functions for generating inline row actions
 * and column definitions for a data grid.
 *
 * @returns An object containing:
 *   - `getInlineRowActionsColumnDefs`: A function to generate column definitions for inline row actions.
 */
import React from "react";
import {
  HorizontalMoreButtonItem,
  HorizontalMoreButtonItemIcon,
} from "@nokia-csf-uxr/ccfk/HorizontalMoreButton";
import { Tooltip } from "@nokia-csf-uxr/ccfk";
import KEY_CODES from "@nokia-csf-uxr/ccfk/common/KeyCodes";

const { ENTER_KEY, SPACE_KEY } = KEY_CODES;
const isSelectionKeyPressed = (key) =>
  key && (key === ENTER_KEY || key === SPACE_KEY);

const useDataGrid = () => {
  /**
   * getInlineRowActions - Generates inline row actions for a data grid.
   *
   * @param params - The parameters for the row actions.
   * @param inlineActions - An array of action objects, each containing:
   *   - `onClick`: A function to be called when the action is clicked.
   *   - `ariaLabel`: A string for the aria-label attribute.
   *   - `tooltip`: A string for the tooltip text.
   *   - `icon`: A React node representing the icon for the action.
   *   - `id`: A unique identifier for the action.
   * @returns An array of React elements representing the inline row actions.
   */
  const getInlineRowActions = (params, inlineActions) => {
    const MEDIUM_BUTTON_SIZE = "1.125rem";
    const __inlineActions = inlineActions.map((action, index) => {
      return (
        <HorizontalMoreButtonItem
          onClick={() => action.onClick(params)}
          style={{
            width: MEDIUM_BUTTON_SIZE,
            height: MEDIUM_BUTTON_SIZE,
            minWidth: MEDIUM_BUTTON_SIZE,
          }}
          onKeyDown={(e) => {
            if (isSelectionKeyPressed(e.key)) {
              action.onClick(params);
            }
          }}
          aria-label={action.ariaLabel}
          key={`${action.id}_${index}`}
        >
          {
            <Tooltip tooltip={action.tooltip} placement="bottom">
              <HorizontalMoreButtonItemIcon
                style={{
                  width: MEDIUM_BUTTON_SIZE,
                  height: MEDIUM_BUTTON_SIZE,
                  minWidth: MEDIUM_BUTTON_SIZE,
                }}
              >
                {action.icon}
              </HorizontalMoreButtonItemIcon>
            </Tooltip>
          }
        </HorizontalMoreButtonItem>
      );
    });
    return __inlineActions;
  };

  /**
   * getInlineRowActionsColumnDefs - Generates column definitions for inline row actions in a data grid.
   *
   * @param field - The field name for the action column. If not provided, defaults to 'rowActionColId'.
   * @param inlineActions - An array of action objects, each containing:
   *   - `onClick`: A function to be called when the action is clicked.
   *   - `ariaLabel`: A string for the aria-label attribute.
   *   - `tooltip`: A string for the tooltip text.
   *   - `icon`: A React node representing the icon for the action.
   *   - `id`: A unique identifier for the action.
   * @returns An object representing the column definitions for the inline row actions.
   */
  const getInlineRowActionsColumnDefs = (field, inlineActions) => ({
    type: "actionColumn",
    headerName: "",
    minWidth: 50,
    maxWidth: 50,
    field: field && field.length > 0 ? field : "rowActionColId",
    pinned: "right",
    cellRendererParams: {
      horizontalMoreButtonProps: {
        "aria-label": "More",
      },
      // compactMode:false,
      getRowActions: undefined,
      getInlineRowActions: (params) => getInlineRowActions(params, inlineActions),
      isDynamic: () => false,
      getProgressIndicator: () => undefined,
      getNextFocusableElAfterGrid: () =>
        document.querySelector("[class*=Toggle-]"),
    },
  });
  return { getInlineRowActionsColumnDefs };
};

export default useDataGrid;
