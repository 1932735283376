import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { Skeleton } from "@nokia-csf-uxr/ccfk";
import CustomTab from "../Components/Tab/CustomTab";
import Planner from "../NDACPlanner/Planner";
import { RootState } from "Store/mainStore";
import { useSelector } from "react-redux";

interface IGenericTabView {
  fieldset: string[];
  renderDynamicContent?: Function;
  renderActionButton?: Function;
}

const GenericTabView = ({
  fieldset,
  renderDynamicContent,
  renderActionButton,
}: IGenericTabView) => {
  const [tabData, setTabData] = useState([]);
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );

  useEffect(() => {
    if (Array.isArray(fieldset) && fieldset.length > 0) {
      const lastTabIndex = fieldset.length - 1;
      const tabData = fieldset.map((field, __index) => {
        return {
          label: field["label"] ? field["label"] : `Tab ${__index}`,
          tab: field["id"] ? field["id"] : `Tab${__index}`,
          index: __index,
          content: getTabDynamicContent(field,lastTabIndex === __index),
        };
      });
      if (tabData.length > 0) {
        setTabData(tabData);
      }
    }
  }, [fieldset]);

  const [searchDASButton,setSearchDASButton] = useState(false)

  useEffect(() => { 
    if(configstepperSelector.data.active_step === 'connectivity' && dynamicSelector.data.element[configstepperSelector.data.active_step]) {
      if(dynamicSelector.data.element[configstepperSelector.data.active_step]['ibwave_input_available'] && _.toLower(dynamicSelector.data.element[configstepperSelector.data.active_step]['ibwave_input_available']['key']) === 'yes') {
        setSearchDASButton(true);
      } else {
        setSearchDASButton(false);
      }
    } 
  }, [dynamicSelector.data.element[configstepperSelector.data.active_step] && dynamicSelector.data.element[configstepperSelector.data.active_step]['ibwave_input_available']]);
  

  /**
   * Generates dynamic content for a tab based on the provided field.
   *
   * @param {Object} field - The field object containing tab information.
   * @param {string} field.id - The unique identifier for the tab.
   * @param {string} field.label - The label for the tab.
   * @returns {JSX.Element} The JSX element representing the dynamic content for the tab.
   */
  const getTabDynamicContent = (field,isLastTab) => {
    if (field["id"] === "available_radio_network_plan_s") {
      return (
        <Fragment key={0}>
          <div style={{ marginTop: "10px" }}>
            <Planner />
          </div>
        </Fragment>
      );
    }

    if (field["field_details"] && _.isObject(field["field_details"])) {
      return (
        <div style={{ marginLeft: "2%", marginTop: "10px" }}>
          {/* Render dynamic fields and pass tab id to customize the search block */}
          {renderDynamicContent(field["field_details"],false,undefined,field["id"],searchDASButton)}
          {isLastTab && renderActionButton()}
        </div>
      );
    }
    return <div>Under construction - {field["id"]}</div>;
  };

  return (
    <>
      {tabData.length === 0 && <Skeleton />}
      {tabData.length > 0 && <CustomTab tabData={tabData} />}
    </>
  );
};

export default React.memo(GenericTabView);
