import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "Components/Misc/CaseDetails_System";
import { setProp } from "Store/Actions";
import { setPropT } from 'Store/Actions/ts';
import setObj from "lodash/set";

const generalView = {
    annual_capacity: 0,
    area: 0,
    contract_duration: 1,
    additionalFeature: [],
    commercial_model: "opex",
}
const generalOptions = {
    caseIndustry: ""
}
const initialData = {
    view: {
        PTDAC: { ...generalView }
    },
    options: {
        PTDAC: {
            ...generalOptions,
            ndac_ref: {
                // fullMiningType: "underground",
                // Making default selection - it will be dynamic in future
                fullMiningType: "open",
                fullSolType:"ndac"
            }
        }
    }
};
const businessSlice = createSlice({
    initialState: {
        data: initialData,
        status: STATUS.IDLE,
    },
    name: 'business',
    reducers: {
        setBusiness(state, action) {
            switch (action.payload.method) {
                case 'set/contract_duration':
                    state.data.view.PTDAC.contract_duration = 1; //reseting the state min when changed capex/opex
                    state.data.view.PTDAC.contract_duration = action.payload.params.contract_duration;
                    break;
            }
        }
    },
    extraReducers(builder) {
        builder.addCase(setProp, (state, action: setPropT) => {
            if (action.payload.slice === businessSlice.name) {
                setObj(state.data, action.payload.key, action.payload.value);
            }
        })
    }
});

export default businessSlice.reducer;
export const { setBusiness } = businessSlice.actions;