import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "Store/mainStore";
import _ from "lodash";
import { setProp } from "Store/Actions";
import { setTheme } from "Store/general/theme";
import { setError } from "Store/general/auth";
import CONFIG_API from "Constants/services/configurator";
import { systemStyle } from "Constants/StyleLayer";

export const useConfigurator = () => {
  const dispatch = useDispatch();
  const themeSelector = useSelector((state: RootState) => state.theme);
  
  const stateMachineSelector = useSelector(
    (state: RootState) => state.stateMachine
  );
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const active_step = useSelector(
    (state: RootState) => state.config_stepper.data.active_step
  );
  const activeSubStep = useSelector(
    (state: RootState) => state.config_stepper.data.active_substep
  );
  
  const solutionCategory = configstepperSelector.data.solutionCategory;
  const solutionType = configstepperSelector.data.solutionType;

  /** setPageFeedbacks */
  const setPageFeedbacks = (
    type = "",
    isActive = false,
    message = "",
    variant = "warning"
  ) => {
    // Define available variants
    const allowedVariants = ["warning", "error", "info", "success"];
    const reduxStore = store.getState();

    let pageFeedbacks = [...reduxStore.dynamic.data.pageFeedbacks];
    if (type !== "") {
      // Remove the existing feedback from the store
      pageFeedbacks = pageFeedbacks.filter(
        (feedback) => feedback.type !== type
      );
      if (isActive && message.length > 0) {
        pageFeedbacks.push({
          type,
          variant: allowedVariants.includes(variant) ? variant : "warning",
          message,
        });
      }
      // Update the store with modifed feedback list
      dispatch(
        setProp({
          slice: "dynamic",
          key: "pageFeedbacks",
          value: pageFeedbacks,
        })
      );
    }
  };


  /** getClassBlock */
  const getClassBlock = () => {
    let style = {};
    if (
      (_.includes(["NDAC", "MPW"], solutionCategory) && solutionType !== 'Standalone')
    ) {
      if (configstepperSelector.data.active_step === "devices") {
        style["display"] = "flex";
        style["justifyContent"] = "space-between";
        return style;
      } else if (configstepperSelector.data.active_step === "connectivity") {
        // const deviceBlock = dynamicSelector.data.dataFromPackage['deviceBlock'];
        // const wrapPresent = configstepperSelector.data.fieldsInformation[configstepperSelector.data.active_step] && configstepperSelector.data.fieldsInformation[configstepperSelector.data.active_step].wrapAfter && (configstepperSelector.data.fieldsInformation[configstepperSelector.data.active_step].wrapAfter).some(r=> (_.includes(_.keys(deviceBlock),r)));
        // console.log("wrapPresent",deviceBlock,configstepperSelector.data.fieldsInformation[configstepperSelector.data.active_step].wrapAfter);

        if(configstepperSelector.data.solution_selected === 'ptdac') {
          style["display"] = "flex";
          style["justifyContent"] = "space-between";
          style['flexWrap'] = 'wrap';
          return style;
        } else {
          // this is for grouping to be shown in connectivity as group of 2
          return configstepperSelector.data.solution_selected !== 'ndac_compact' ? systemStyle.genrateGridCols(2) : systemStyle.genrateGridCols(1);
        }
      }
    }
  };

  /** getContainerBasedElemnt */
  const getContainerBasedElements = (argActive_step = "", argactiveSubStep = "", package_data?) => {
    const activeStep = argActive_step !== "" ? argActive_step : active_step;
    const active_SubStep = argactiveSubStep !== "" ? argactiveSubStep : activeSubStep;
    const packageData = package_data ? package_data : configstepperSelector.data.solution_form_data["packages"];
    let containerElements = [];
    if (
      packageData &&
      packageData[activeStep] &&
      packageData[activeStep].field_details
    ) {
      let fieldList = packageData[activeStep].field_details;
      if (fieldList[active_SubStep] && fieldList[active_SubStep].field_details) {
        // Handle the sub step field lis
        fieldList = fieldList[active_SubStep].field_details
      }
      containerElements = parseContainerElements(fieldList);

      // Object.keys(
      //   configstepperSelector.data.solution_form_data["packages"][activeStep]
      //     .field_details
      // ).forEach((field) => {
      //   if (
      //     configstepperSelector.data.solution_form_data["packages"][activeStep]
      //       .field_details[field].type === "container"
      //   ) {
      //     containerElements.push(
      //       configstepperSelector.data.solution_form_data["packages"][
      //         activeStep
      //       ].field_details[field]
      //     );
      //   }
      // });
    }
    return containerElements;
  };

  /** parseContainerElements */
  const parseContainerElements = (field_details) => {
    const containerElements = [];
    Object.keys(field_details).forEach((field) => {
      if (field_details[field].type === "container") {
        containerElements.push(field_details[field]);
      }
    });
    return containerElements;
  };

  /** getContainerElements */
  const getContainerElements = (containerElements) => {
    let field_details = {};
    // Prepare container based package list
    containerElements.forEach((container) => {
      field_details = { ...field_details, ...container.field_details };
    });
    return field_details;
  };

  /** getHiddenFieldDataCallBack  */
  const getHiddenFieldDataCallBack = async (data) => {
    try {
      const method = data[1]["data_callback"]["method"];
      const endpoint = data[1]["data_callback"]["endpoint"];

      // let params = "sid" + "=" + dynamicSelector.data.sid.toString();
      let params = `sid=${dynamicSelector.data.sid.toString()}`;
      const resp = await CONFIG_API.configuratorDataCallback(
        method,
        endpoint,
        params
      );
      // if(catalogRes.length > 0) {
      //     resp.data = catalogRes;
      // }
      if (resp.status && resp.data) {
        let datavalue = Object.values(resp.data);
        let selectKeyValue = {};
        selectKeyValue["key"] = datavalue[0];
        selectKeyValue["value"] = datavalue[0];
        if (datavalue[0] !== 0) {
          let dataSetValue = {
            ...dynamicSelector.data.element[
            configstepperSelector.data.active_step
            ][data[0]],
            ...selectKeyValue,
          };
          dispatch(
            setProp({
              slice: "dynamic",
              key: `element.${configstepperSelector.data.active_step}.${[
                data[0],
              ]}`,
              value: dataSetValue,
            })
          );
        }
      } else {
        dispatch(
          setError({
            key: "error",
            value: {
              errorCode: resp.errorCode,
              errorMsg: resp.errorMessage,
            },
          })
        );
      }
    } catch (e) {
      dispatch(
        setError({
          key: "error",
          value: {
            errorCode: e,
            errorMsg: "Timeout",
          },
        })
      );
    } finally {
      dispatch(setError("reset"));
    }
  };

  /** getDataCallbackMMS */
  const getDataCallbackMMS = async (fieldname, data, mms) => {
    try {
      const method = data[1][mms]["data_callback"]["method"];
      const endpoint = data[1][mms]["data_callback"]["endpoint"];
      let params = "";
      // ------------- Temp workaround and should be deleted
      // if(fieldname === "use_cases_apps"){
      //   const value = mms === "min" ? 1 : (mms === "max" ? 400 : 1);
      //   dispatch(
      //     setProp({
      //       slice: "dynamic",
      //       key: `elementMMS.${fieldname}.${[data[0]]}.${mms}`,
      //       value: value,
      //     })
      //   );
      // }
      // else{
      const resp = await CONFIG_API.configuratorDataCallback(
        method,
        endpoint,
        params
      );
      const roundedVal = Math.floor(resp.data[0][mms]);
      dispatch(
        setProp({
          slice: "dynamic",
          key: `elementMMS.${fieldname}.${[data[0]]}.${mms}`,
          value: roundedVal,
        })
      );
      if(mms === "min") {
        dispatch(setProp({ slice: "dynamic", key:`element.${fieldname}.${[data[0]]}`, value: {key : roundedVal,value : roundedVal} }));
      }
    } catch (e) {
      dispatch(
        setError({
          key: "error",
          value: {
            errorCode: e,
            errorMsg: "Timeout",
          },
        })
      );
      // setisDisabled(false);
    } finally {
      dispatch(setError("reset"));
      // setAPILoad(false);
    }
  };

  /**  reset dynamic list on add accessories dialog cancel */
  const resetDeviceAccessories = (catalog, active_step, data_name) => {
    const keysMenu = _.keys(catalog);

    keysMenu.forEach((device_name) => {
      let product_list = dynamicSelector.data.element[active_step] && dynamicSelector.data.element[active_step][data_name] && dynamicSelector.data.element[active_step][data_name][device_name]
        ? dynamicSelector.data.element[active_step][data_name][device_name]
        : [];
      let productChanges = product_list.map((item) => ({
        ...item,
        checked: false,
      }));

      dispatch(
        setProp({
          slice: "dynamic",
          key: `element.${active_step}.${[data_name]}.${[device_name]}`,
          value: productChanges,
        })
      );
    });

    dispatch(
      setTheme({
        element: "modal",
        comp: "connectivity_catalog",
        prop: "isOpen",
        value: false,
      })
    );
  };

  /**  getPackageFieldList */
  const getPackageFieldList = (__active_step, __activeSubStep?, package_data?) => {
    const packageData = package_data ? package_data : configstepperSelector.data.solution_form_data["packages"];
    let stepPackageFieldDetails =
      __active_step === "review"
        ? []
        : packageData[__active_step] &&
          packageData[__active_step]["field_details"]
          ? packageData[__active_step]["field_details"]
          : [];
    // Check whether the step / key is container based
    const containerElements = getContainerBasedElements(__active_step, __activeSubStep, packageData);

    if (containerElements.length > 0) {
      stepPackageFieldDetails = getContainerElements(containerElements);
    }
    // Process and get the field details for type == fieldset (Tabular view)
    const { isTabView, tabViewFieldList } = getTabularFieldList(stepPackageFieldDetails);
    if (isTabView) {
      // stepPackageFieldDetails = {};
      tabViewFieldList.forEach((tabField) => {
        if(tabField.field_details){
          stepPackageFieldDetails = {...stepPackageFieldDetails,...tabField.field_details};
        }
      });
    }
    return stepPackageFieldDetails;
  };

  const getAllPackageFields = () => {
    const stepKeys = _.keys(configstepperSelector.data.solution_form_data['packages']);
    let allFields = {};
    _.each(stepKeys, (eachStep) => {
      const eachStepFieldDetails = getPackageFieldList(eachStep);
      allFields[eachStep] = eachStepFieldDetails;
    });
    return allFields;
  }

  /** setOptionsGlobalState */
  const setOptionsGlobalState = () => {
    if (
      !themeSelector.data.button.isToggle.hasOwnProperty(
        `${stateMachineSelector.PTDAC.activeTab}_undo`
      ) ||
      !themeSelector.data.button.isToggle[
      `${stateMachineSelector.PTDAC.activeTab}_undo`
      ]
    ) {
      // setisDisabled(false);
      dispatch(
        setTheme({
          element: "button",
          comp: `options_global`,
          prop: "isDisable",
          value: false,
        })
      );
    } else {
      //setisDisabled(true);
      dispatch(
        setTheme({
          element: "button",
          comp: `options_global`,
          prop: "isDisable",
          value: true,
        })
      );
    }
  }

  /** preparePackageElementContent - this to sep the search block elements and other elements */
  const preparePackageElementContent = (__packageDataDetails) => {
    let jsonData = {};
    let deviceBlock = {};
    const solutionCategory = configstepperSelector.data.solutionCategory;
    const currentStepFieldGroups =
      configstepperSelector.data.active_step === "sla_warranty"
        ? null
        : configstepperSelector.data.fieldsInformation[
        configstepperSelector.data.active_step
        ] &&
        configstepperSelector.data.fieldsInformation[
        configstepperSelector.data.active_step
        ]["grouping"];
    if (
      (_.includes(['NDAC','MPW'],solutionCategory)) &&
      currentStepFieldGroups && solutionType !== 'Standalone'
    ) {
      Object.entries(__packageDataDetails).forEach(function (item) {
        if (_.includes(currentStepFieldGroups, item[0])) {
          deviceBlock[item[0]] = item[1];
        } else {
          jsonData[item[0]] = item[1];
        }
      });
    } else {
      jsonData = __packageDataDetails;
    }
    return { dB: deviceBlock, jD: jsonData };
  }

  const getListOfFields = (dynamicFieldsValues) => {
    // flatten the object to see all fields
    const allfieldsList = _.reduce(dynamicFieldsValues, (result, value) => {
      _.assign(result, value);
      return result;
    }, {});
    return allfieldsList;
  }

  /** getSubStepFieldList */
  const getSubStepFieldList = (stepName, subStepName) => {
    const packageData = configstepperSelector.data.solution_form_data["packages"];
    let containerfields = [];
    if (
      packageData &&
      packageData[stepName] &&
      packageData[stepName].field_details
    ) {
      let fieldList = packageData[stepName].field_details;
      if (fieldList[subStepName] && fieldList[subStepName].field_details) {
        // Handle the sub step field lis
        fieldList = fieldList[subStepName].field_details
      }
      containerfields = parseContainerElements(fieldList);
    }
    return containerfields;
  }

  /** getSubStepFieldNameList */
  const getSubStepFieldNameList = (stepName, subStepName) => {
    const containerfields = getSubStepFieldList(stepName, subStepName);
    let containerElements = [];
    containerfields.forEach(fRow => {
      if (fRow.field_details) {
        Object.keys(fRow.field_details).forEach(key => {
          containerElements.push(key);
        });
      }
    });
    return containerElements;
  }

  const getSubStepFieldDefaultValue = (stepName, subStepName, key) => {
    const containerfields = getSubStepFieldList(stepName, subStepName);
    let defaultValue = "";
    containerfields.forEach(fRow => {
      if (fRow.field_details) {
        Object.keys(fRow.field_details).forEach(__key => {
          if (__key === key) {
            defaultValue = fRow.field_details[__key]["default"] ? fRow.field_details[__key]["default"] :((_.includes(['number','range'],fRow.field_details[__key]['type']) ? fRow.field_details[__key]['min'] : ""));
            // Added by : Pratima (01-08-24) 
            // we need to make hidden elements data as 0 and not ""
            //  else it will throw error
            if (fRow.field_details[__key]['type'] === 'hidden' && _.includes(__key, 'count_of') || _.includes(__key, 'total_')) {
              defaultValue = "0";
            }
          }
        });
      }
    });
    return defaultValue;
  }

  /** __getContainerFieldDetails */
  const __getContainerFieldDetails = (stepFieldDetails) => {
    const containerElements = [];
    Object.keys(stepFieldDetails).forEach((field) => {
      if (stepFieldDetails[field].type === "container") {
        containerElements.push(stepFieldDetails[field]);
      }
    });
    let fieldDetails = {};
    // Prepare container based package list
    containerElements.forEach((container) => {
      fieldDetails = { ...fieldDetails, ...container.field_details };
    });
    return { isContainer: containerElements.length > 0, fieldDetails };
  }

  // we have to change this method logic to loop step and substep and get stepwise fields
  const getEntirePackages = () => {
    const stepKeys = _.keys(configstepperSelector.data.solution_form_data['packages']);
    let allFields = {};
    _.each(stepKeys, (eachStep) => {
      const allsteps = configstepperSelector.data.stepper_data;
      const currentStep = _.filter(allsteps,{stepname:eachStep});
      const substeps = currentStep.length > 0 && currentStep[0]['subSteps'];
      if(substeps && substeps.length > 0) {
        // gets stepwise fields of steps and substeps
        _.each(substeps, (eachsubstep) => {
          const eachStep_subStepFieldDetails = getPackageFieldList(eachStep,eachsubstep.stepname);
          allFields[eachStep] = {...allFields[eachStep],...eachStep_subStepFieldDetails};
        })
      } else {
        // gets stepwise fields of steps only
        const eachStepFieldDetails = getPackageFieldList(eachStep);
        allFields[eachStep] = {...allFields[eachStep],...eachStepFieldDetails};
      }
    });
    return allFields;
  }

  const getAllFieldsInPackage = () => {
    let getPackageData_stepwise = getEntirePackages();
    const packageDataAllFields = getListOfFields(getPackageData_stepwise);
    return packageDataAllFields;
  }

  /**
  * Determines if the given package data should be displayed in a tabular view and retrieves the list of fields for the tab view.
  *
  * @param {object} activePackages - The package data to be evaluated.
  * @returns {object} An object containing:
  * - `isTabView` (boolean): Indicates if the package data should be displayed in a tabular view.
  * - `tabViewFieldList` (Array): The list of fields to be displayed in the tab view.
  */
  const getTabularFieldList = (activePackages) => {
    let isTabView = false;
    let tabViewFieldList = [];
    if (typeof activePackages === 'object' && activePackages !== null) {
      isTabView = Object.values(activePackages).some(
        (packageItem) => packageItem["type"] === 'fieldset'
      );
    }
    if(isTabView){
      // console.log("activePackages", activePackages);
      Object.keys(activePackages).forEach((packageKey) => {
        if(activePackages[packageKey]["type"] === 'fieldset'){
          tabViewFieldList.push(activePackages[packageKey])
        }
      });
    }
    return {isTabView, tabViewFieldList};
  }

  return {
    setPageFeedbacks,
    getClassBlock,
    getContainerBasedElements,
    getContainerElements,
    parseContainerElements,
    getHiddenFieldDataCallBack,
    getDataCallbackMMS,
    resetDeviceAccessories,
    getPackageFieldList,
    setOptionsGlobalState,
    preparePackageElementContent,
    getListOfFields,
    getAllPackageFields,
    getSubStepFieldList,
    getSubStepFieldNameList,
    getSubStepFieldDefaultValue,
    getAllFieldsInPackage,
    getTabularFieldList
  };
};
