import { Tabs } from "@nokia-csf-uxr/ccfk";
import { ChipLabel, InfoChip } from "@nokia-csf-uxr/ccfk/Chip";
import { Tab } from "@nokia-csf-uxr/ccfk/Tabs";
import { EmptyState } from "Components/Content/EmptyState";
import {
  removeCartItem,
  setCart,
  totalCost,
} from "Store/contentState/cartBoqSlice";
import { RootState } from "Store/mainStore";
import { patchDurationItems, roundCount } from "Utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "Components/Elements/Loader";
import _ from "lodash";
import BoqDataGrid from "./BoqDataGrid";
import { BoqDetails } from "../configurationArea/Configurator/hooks/useCartDetails";
import Checkbox, { CheckboxLabelContent } from "@nokia-csf-uxr/ccfk/Checkbox";
import { TextInputLabelContent } from "@nokia-csf-uxr/ccfk/TextInput";
import { setProp } from "Store/Actions";
import CartBoQConfirmModal from "./CartBoQConfirmModal";

const CartBoq = () => {
  // Selectors
  const cartBoqSelector = useSelector(
    (state: RootState) => state.undoCartBoq.present
  );
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const caseDetailsSelector = useSelector(
    (state: RootState) => state.caseDetails
  );
  const businessSelector = useSelector((state: RootState) => state.business);
  const warrantySelector = useSelector((state: RootState) => state.warranty);
  const themeSelector = useSelector((state: RootState) => state.theme);
  const stateMachineSelector = useSelector(
    (state: RootState) => state.stateMachine
  );
  let cartTotal = useSelector((state: RootState) => totalCost(state.cartBoq));
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  // States
  const [isConfirmPopOpen, setIsConfirmPopOpen] = useState(false);
  const [isBoQEditableLocal, setIsBoQEditableLocal] = useState(false);
  // Hooks
  const dispatch = useDispatch();
  const autoSizeFitAll = (gridOptions) => {
    window["gridOptions"] = gridOptions;
    const allColumnIds = [];
    gridOptions.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridOptions.columnApi.autoSizeAllColumns(false);
  };
  const [finalTotal, setfinalTotal] = useState(roundCount(cartTotal));
  const { portBoqGrid, referenceBoqGrid, cmuBoqGrid, cdmBoqGrid, MPWGrid,get_slaDuration_basedOnCommercialModel } = BoqDetails();

  const undoBtnStatus =
    themeSelector.data.button.isToggle[
      `${stateMachineSelector.PTDAC.activeTab}_undo`
    ];
  const [modifiedcartItems, setmodifiedcartItems] = useState([]);

  const solutionCategory = configstepperSelector.data.solutionCategory;
  const solutionType = configstepperSelector.data.solutionType;

  useEffect(() => {
    let durations = {
      contract_duration: businessSelector.data.view.PTDAC.contract_duration,
      sla_duration: warrantySelector.data.options.PTDAC.subscription_duration,
      warranty_duration: warrantySelector.data.options.PTDAC.warranty[0],
    };
    //  for non ptdac and non cmu
    if (
      solutionType !== "Standalone" &&
      businessSelector.data.options.PTDAC.caseIndustry !== "port"
    ) {
      const slaNDACDur = get_slaDuration_basedOnCommercialModel();
      const warrantyNDACDuration =
        dynamicSelector.data.dataSet["extend_warranty_by"];
      durations = {
        contract_duration: businessSelector.data.view.PTDAC.contract_duration,
        sla_duration: slaNDACDur,
        warranty_duration: warrantyNDACDuration,
      };
    }
    const cartItems = patchDurationItems(
      cartBoqSelector.data.cart.items,
      durations
    );
    setmodifiedcartItems(cartItems);
    // re calculate the total after modifying the cart items
    let cartTotal = 0;
    // dont total the items for which type is empty
    let validCartItems = cartItems.filter((item) => item.type !== "");
    validCartItems.forEach((obj) => {
      // Convert cost to number
      const cost = parseFloat(obj.cost);

      // Check if cost is a valid number (not NaN)
      if (!isNaN(cost)) {
        // Add to sum
        cartTotal += cost;
      }
    });
    cartTotal = roundCount(cartTotal);

    setfinalTotal(cartTotal);
  }, [undoBtnStatus, cartBoqSelector.data.cart.items]);

  const loadCMU = () => {
    let cmu = cmuBoqGrid(modifiedcartItems);
    return cmu && <BoqDataGrid boqGridList={cmu} />;
  };

  const loadOther = () => {
    let boqGriddata = [];
    if (authSelector.system.isBoqLoading) {
      return (
        <div className="boqloader">
          <Loader />
        </div>
      );
    } else {
      switch (businessSelector.data.options.PTDAC.caseIndustry) {
        case "port":
          boqGriddata = portBoqGrid(modifiedcartItems);
          break;

        case "mining":
          boqGriddata = cdmBoqGrid(modifiedcartItems);
          break;

        default:
          boqGriddata = referenceBoqGrid(modifiedcartItems);
      }
      return <BoqDataGrid boqGridList={boqGriddata}></BoqDataGrid>;
    }
  };

  const loadMPW = () => {
    // const cmu_boq = loadCMU();
    // const reference_boq = loadOther();
    // return <>{cmu_boq}{reference_boq}</>
    let mpw = MPWGrid(modifiedcartItems);
    return mpw && <BoqDataGrid boqGridList={mpw} />;
  };

  /**
   * Toggles the edit mode for the Bill of Quantities (BoQ).
   * Opens a confirmation modal and saves the current cart state if not already editable.
   * @param e - The change event from the checkbox.
   */
  const handleBoQEditToggle = (e) => {
    let isBoQEditable = e.target.checked;
    setIsBoQEditableLocal(isBoQEditable);
    setIsConfirmPopOpen(true);
    if(!(cartBoqSelector.data.isBoQEditable)){
      // Save the current cart to undo cart
      saveCurrentCartState();
    }
  }
  /**
   * Closes the cart edit confirmation modal and toggles the BoQ edit state.
   */
  const closeCartEditConfirmModal = () => {
    setIsConfirmPopOpen(false);
    const __isBoQEditableLocal = !(isBoQEditableLocal);
    setIsBoQEditableLocal(__isBoQEditableLocal);
    dispatch(setProp({ slice: "cartBoq", key: "isBoQEditable", value: __isBoQEditableLocal }));
  }
  /**
   * Confirms the BoQ edit action.
   * Closes the confirmation modal and either restores the cart to the last saved state or sets the BoQ as editable.
   */
  const confirmBoQEdit = () => {
    setIsConfirmPopOpen(false);
    if(!isBoQEditableLocal){
      // restore the cart to the last saved state
      restoreSaveCartState();
    }
    dispatch(setProp({ slice: "cartBoq", key: "isBoQEditable", value: isBoQEditableLocal }));    
  };

  /**
   * Saves the current state of the cart to enable undo functionality.
   * Stores the current cart items in a backup state and sets the BoQ as editable.
   */
  const saveCurrentCartState = () => {
    // Save the current cart to undo cart
    const __cartItem = {...cartBoqSelector.data.cart};

    dispatch(setProp({ slice: "cartBoq", key: "cartBakup", value: __cartItem  }));
    // set the isBoQEditable
    dispatch(setProp({ slice: "cartBoq", key: "isBoQEditable", value: isBoQEditableLocal }));
  }

  /**
   * Restores the saved state of the shopping cart from local storage.
   * This function retrieves the cart data and updates the cart state accordingly.
   */
  const restoreSaveCartState = () => {
    // Restore the cart to the last saved state
    const __cartBakup = {...cartBoqSelector?.data?.["cartBakup"]};
    // Make quantity field to integer
    const __cartBakupItems = __cartBakup?.items.map(item => ({
      ...item,
      quantity: parseInt(item.quantity, 10) // or use +item.quantity
    }));
    // Save the current cart to undo cart    
    dispatch(setCart(__cartBakupItems));
  }

  /**
   * Confirms the deletion of a BoQ item.
   * Dispatches the removeCartItem action and closes the delete confirmation modal.
   */
  const confirmBoQItemDelete = () => {
    // Call removeCartItem action
    dispatch(removeCartItem(new Object({ ...cartBoqSelector.data?.["boQDeleteKey"], quantity: cartBoqSelector.data?.["boQDeleteKey"]?.quantity })))
    // Close the modal
    closeBoQDeleteModal();
  };

  /**
   * Closes the BoQ item delete confirmation modal.
   */
  const closeBoQDeleteModal = () => {
    dispatch(setProp({ slice: "cartBoq", key: "boQDeleteKey", value: "" }));
  };
  
  return (
    <>
      {/* Delete Cart Item Confirmation*/}
      <CartBoQConfirmModal 
        isOpen={cartBoqSelector.data?.["boQDeleteKey"] && cartBoqSelector.data?.["boQDeleteKey"] !== ""} 
        confirmAction={confirmBoQItemDelete}
        closeModal={closeBoQDeleteModal}
        title={"Delete Cart Item ?"}
        message={<><p>Are you sure you want to delete the selected cart item ?</p><p>This action cannot be undone.</p></>}
        />
      {/* Delete Cart Item Confirmation*/}
      {/* Edit BoQ Confirmation*/}
      <CartBoQConfirmModal 
        isOpen={isConfirmPopOpen} 
        confirmAction={confirmBoQEdit}
        closeModal={closeCartEditConfirmModal}
        title={(isBoQEditableLocal && !cartBoqSelector.data.isBoQEditable) ? "Customize Existing BoQ ?" : "Cancel Customization ?"}
        message={(isBoQEditableLocal && !cartBoqSelector.data.isBoQEditable) ? <><p>Are you sure you want to customize the existing BoQ?</p><p>Any unsaved changes may be lost.</p></>:<><p>Are you sure you want to cancel the customization?</p><p>Any changes made will not be saved.</p></>}
        />
      {/* Edit BoQ Confirmation*/}
      <div
        style={{
          display: "flex",
          padding: "0.2rem",
          justifyContent: "space-between",
        }}
      >
        <Tabs style={{ justifyContent: "start" }}>
          <Tab onSelect={() => {}} selected>
            Bill Of Quantities
          </Tab>
        </Tabs>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          {
            configstepperSelector.data.active_step === "review" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRight: "1px solid var(--g-color-app-banner-divider)",
              }}
            >
              {/* <Checkbox
                id="customiseBoQ"
                checked={cartBoqSelector.data.isBoQEditable}
                onChange={handleBoQEditToggle}
              />
              <CheckboxLabelContent>Customise exiting BoQ</CheckboxLabelContent> */}
            </div>
            )
          }
          
          <div>
            <TextInputLabelContent>Total Price: </TextInputLabelContent>
          </div>
          <div>
            <InfoChip
              size="small"
              style={{
                border: "2px solid var(--g-color-app-banner-background)",
              }}
            >
              <ChipLabel
                labelProps={{ style: { fontWeight: "bolder" } }}
                label={`${roundCount(finalTotal)} ${
                  caseDetailsSelector.data.currency
                }`}
              />
            </InfoChip>
          </div>
        </div>
      </div>
      {!modifiedcartItems || modifiedcartItems.length === 0 ? (
        authSelector.system.isBoqLoading ? (
          <div className="boqloader">
            <Loader />
          </div>
        ) : (
          <EmptyState msg="Offer contents will appear here after making configuration choices." />
        )
      ) : (
        <>
          {solutionType === "Standalone" && dynamicSelector.data.boq.length > 0
            ? loadCMU()
            : solutionCategory === "MPW"
            ? loadMPW()
            : loadOther()}
        </>
      )}
    </>
  );
};

export default CartBoq;
