// import { AppToolbar, Breadcrumbs, IconButton, Snackbar } from "@nokia-csf-uxr/ccfk";
import { AppToolbar, Breadcrumbs, Snackbar } from "@nokia-csf-uxr/ccfk";
// import OpenInNewIcon from "@nokia-csf-uxr/ccfk-assets/legacy/OpenInNewIcon";
import HomeIcon from '@nokia-csf-uxr/ccfk-assets/legacy/HomeIcon';
import {
  AppBody,
  AppContent,
  AppContentWrapper,
  AppHeader
} from "@nokia-csf-uxr/ccfk/App";
import {
  AppToolbarContent,
  AppToolbarTitle,
  AppToolbarTitleContainer
} from "@nokia-csf-uxr/ccfk/AppToolbar";
import {
  Breadcrumb,
  BreadcrumbCompacts,
  BreadcrumbCompactsHeader,
  BreadcrumbIcon,
  LinkSeparator
} from "@nokia-csf-uxr/ccfk/Breadcrumbs";
import { SnackbarContent } from "@nokia-csf-uxr/ccfk/Snackbar";
import { showModal, showPopup } from "Store/general/auth";
import { RootState } from "Store/mainStore";
import { setTheme } from "Store/general/theme";
import { deserializeFunction } from "Utils";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogModal from "./Elements/DialogModal";
import { useScrollTop } from "./Content/configurationArea/Configurator/hooks/useScrollTop";

export const  Body = (prop) => {
  const dispatch = useDispatch();
  const themeSelector = useSelector((state: RootState) => state.theme);
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const configuratorSelector = useSelector((state: RootState) => state.config_stepper);

  // Auto scroll to top on step change
  const element = document.getElementById('dot-app-content');  
  const {isScrolled} = useScrollTop(element);
  useEffect(() => {
    if(isScrolled && element){
      element.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [configuratorSelector.data.active_step,configuratorSelector.data.active_substep]);
  // ---- Need to move the scroll logic effect to hook
  // --- Will check and pick later
  // setScrollDependency([configuratorSelector.data.active_step,configuratorSelector.data.active_substep])
  
  
  return (
    <>
      <AppBody>
        <AppContentWrapper>
          {/* <div className="p"> */}
          {prop.crumbs && (
            <div style={{
              paddingLeft: '1rem',
              paddingRight: '1rem',
            }}>
              <AppHeader>
                <AppToolbar elevationProps={{ elevationIndex: 0, }}>
                  <AppToolbarTitleContainer style={{maxWidth:'100%',padding:'0',margin: '2px 0 0 2px'}}>
                    <AppToolbarTitle>
                      <Breadcrumbs className="dot__Breadcrumbs">
                        <BreadcrumbCompacts>
                          <BreadcrumbCompactsHeader>
                            {[
                              prop.crumbs.map((crum, index, array) => {
                                return (
                                  <Fragment key={index}>
                                    {/* <Breadcrumb onClick={() => window.open(process.env.REACT_APP_REDIRECT_URI_ZENDESK, "_self")}>
                                      <BreadcrumbIcon>
                                        <HomeIcon />
                                      </BreadcrumbIcon>
                                    </Breadcrumb> */}
                                    {crum}
                                    {index !== array.length - 1 && <LinkSeparator key={index} />}
                                  </Fragment>
                                );
                              }),
                            ]}
                          </BreadcrumbCompactsHeader>
                        </BreadcrumbCompacts>
                      </Breadcrumbs>
                    </AppToolbarTitle>
                    {/* <AppToolbarSubtitle>{prop.subtitle}</AppToolbarSubtitle> */}
                  </AppToolbarTitleContainer>
                  {/* <AppToolbarButtonRow><Typography variant={'BODY'}>{prop.subtitle}</Typography></AppToolbarButtonRow> */}
                </AppToolbar>
              </AppHeader>
            </div>
          )}
          <AppContent
            id="dot-app-content"
            style={{
              paddingLeft: '1rem',
              paddingRight: '1rem',
              paddingTop: "1rem",
              width: "100%",
            }}
          >
            {prop.bodyContent}
            <Snackbar onClick={() => dispatch(showPopup({ msg: "", show: !authSelector.system.popup.show }))} autoCloseDuration={2000} open={authSelector.system.popup.show} autoClose={true} >
              <SnackbarContent>{authSelector.system.popup.msg}</SnackbarContent>
            </Snackbar>
            <DialogModal
              type="popup"
              isOpen={authSelector.system.modal.show}

              popInfo={{
                msg: authSelector.system.modal.msg,
                title: authSelector.system.modal.title,
                variant: authSelector.system.modal.variant,
                panel: {
                  msg: authSelector.system.modal.panel.msg,
                  title: authSelector.system.modal.panel.title,
                  show: themeSelector.data.panel.isOpen.global,
                  onExpand: () => {
                    dispatch(
                      setTheme({
                        element: "panel",
                        comp: "global",
                        prop: "isOpen",
                        value: !themeSelector.data.panel.isOpen.global,
                      })
                    );
                  }
                }
              }}
              //@ts-ignore
              actionBtns={authSelector.system.modal.action.length === 0 ? [{
                label: "Close", variant: "call-to-action", onAction: () => {
                  dispatch(showModal({
                    msg: "",
                    panel: { title: '', msg: '' },
                    show: !authSelector.system.modal.show,
                  }))
                }
              }] : authSelector.system.modal.action.map((payload) => new Object({ ...payload, onAction: deserializeFunction(payload.onAction) }))} />
          </AppContent>
          {/* </div> */}
        </AppContentWrapper>
      </AppBody>
    </>
  );
};
