import React, { useRef } from "react";
import _ from "lodash";
import { Button, Dialog } from "@nokia-csf-uxr/ccfk";
import {
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "@nokia-csf-uxr/ccfk/Dialog";
import { AlertIcon, AlertBar, AlertMessage } from "@nokia-csf-uxr/ccfk/Alert";

import InfoCircleFillIcon from "@nokia-csf-uxr/ccfk-assets/latest/InfoCircleFillIcon";

interface ICartBoQConfirmModal {
  isOpen: boolean;
  title: string;
  message: React.ReactNode | string;
  confirmAction: Function;
  closeModal: Function;
}

const DIALOG_HEIGHT = "12.5rem";
const DIALOG_WIDTH = "28rem";
const EXAMPLE_ALERT_STYLE = {
  top: `calc((100vh - ${DIALOG_HEIGHT}) / 2)`,
  height: DIALOG_HEIGHT,
  minHeight: DIALOG_HEIGHT,
  left: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
  right: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
  width: DIALOG_WIDTH,
  zIndex: 100000,
};

/**
 * CartBoQConfirmModal component renders a confirmation modal dialog.
 *
 * @param {boolean} isOpen - Determines if the modal is open.
 * @param {Function} confirmAction - Function to call when the confirm button is clicked.
 * @param {string} title - Title of the modal.
 * @param {string} message - Message to display in the modal.
 * @param {Function} closeModal - Function to call when the cancel button is clicked.
 *
 * @returns {JSX.Element} The rendered modal dialog component.
 */
const CartBoQConfirmModal = ({
  isOpen,
  confirmAction,
  title,
  message,
  closeModal,
}: ICartBoQConfirmModal) => {
  const appElementRef = useRef(null);
  return (
    <>
      <div ref={appElementRef} />
      <Dialog
        appElement={appElementRef}
        isOpen={isOpen}
        ariaHideApp={false}
        style={{ content: EXAMPLE_ALERT_STYLE }}
        onRequestClose={(event) => {
          console.log(event);
        }}
        parentSelector={() => document.querySelector("#dot-react-portals")}
      >
        <DialogTitle
          title={title}
          icon={
            <AlertIcon variant="CONFIRM">
              <InfoCircleFillIcon />
            </AlertIcon>
          }
        />
        <DialogContent
          isTopDividerVisible={false}
          isBottomDividerVisible={false}
          style={{ padding: "0.25rem 1.3125rem 0 1.5rem", overflow: "hidden" }}
        >
          <AlertMessage
            className="dotCartBoQConfirmModalAlertMessage"
            iconProps={{ style: { display: "none" } }}
            message={message}
            variant="CONFIRM"
          />
        </DialogContent>
        <DialogFooter>
          <Button onClick={() => closeModal()}>Cancel</Button>
          <Button
            variant="call-to-action"
            autoFocus
            onClick={() => confirmAction()}
          >
            Confirm
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default React.memo(CartBoQConfirmModal);
