
/*
    
*/
import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "Components/Misc/CaseDetails_System";
import { setProp } from "Store/Actions";
import { setPropT } from "Store/Actions/ts";
import { RootState } from "Store/mainStore";
import { roundCount, updateExistingProduct } from "Utils";
import _ from "lodash";
const initialData = {
    data: {
        system: {
            prepare: {
                catalog: [],
                suggest: { aps: [], devices: [], mixie_server: [] },
            },
        },
        cart: {
            items: [],
        },
        isBoQEditable: false,
    },
    status: STATUS.IDLE
}
const cartBoqSlice = createSlice({
    name: 'cartBoq',
    initialState: initialData,
    reducers: {
        addCartItem(state, action) {
            //@ts-ignore
            const targetItems = action.payload.hasOwnProperty('targetItems') ? _.get(state.data.system.prepare, action.payload.targetItems) : state.data.cart.items;
            let cartItems = targetItems;
            const itemsToAdd = Array.isArray(action.payload) ? action.payload : action.payload.products;
            const parentSync = Array.isArray(action.payload) ? false : action.payload.hasOwnProperty('parentSync') ? action.payload.parentSync : false;
            const unWrap = Array.isArray(action.payload) ? true : action.payload.hasOwnProperty('unWrap') ? action.payload.parentSync : true;
            if(itemsToAdd && action.payload.configurator) {
                cartItems =  itemsToAdd;
            }
            else if (itemsToAdd) {
                itemsToAdd.forEach((product) => {
                    cartItems = updateExistingProduct(cartItems, product, "+", parentSync, unWrap);
                });
            } else {
                const existingProductIndex = _.findIndex(cartItems, { product_id: action.payload.product_id });
                if (existingProductIndex !== -1) {
                    cartItems = updateExistingProduct(cartItems, action.payload, "+");
                } else {
                    cartItems.push(action.payload);
                }
            }
            if (action.payload.hasOwnProperty('targetItems')) {
                //@ts-ignore
                _.set(state.data.system.prepare, action.payload.targetItems, _.uniqBy(cartItems, v => [v.product_id, v.type].join()))
            } else {
                if(action.payload.configurator || action.payload.ndac_configurator) {
                    state.data.cart.items = [...(cartItems)];
                } else {
                    state.data.cart.items = _.uniqBy(cartItems, v => [v.product_id, v.type].join());
                }
            }

        },
        removeCartItem(state, action) {
            //@ts-ignore
            const targetItems = action.payload.hasOwnProperty('targetItems') ? _.get(state.data.system.prepare, action.payload.targetItems) : state.data.cart.items;
            let cartItems = targetItems;
            const itemsToAdd = Array.isArray(action.payload) ? action.payload : action.payload.products;
            const parentSync = Array.isArray(action.payload) ? false : action.payload.hasOwnProperty('parentSync') ? action.payload.parentSync : false;
            const unWrap = Array.isArray(action.payload) ? true : action.payload.hasOwnProperty('unWrap') ? action.payload.unWrap : true;
            if (itemsToAdd) {
                itemsToAdd.forEach((product) => {
                    cartItems = updateExistingProduct(cartItems, product, "-", parentSync, unWrap);
                });
            } else {
                const existingProductIndex = _.findIndex(cartItems, { product_id: action.payload.product_id });
                if (existingProductIndex !== -1) {
                    cartItems = updateExistingProduct(cartItems, action.payload, "-");
                } else {
                    cartItems.push(action.payload);
                }
            }
            if (action.payload.hasOwnProperty('targetItems')) {
                //@ts-ignore
                _.set(state.data.system.prepare, action.payload.targetItems, _.uniqBy(cartItems, v => [v.product_id, v.type].join()))
            } else {
                state.data.cart.items = _.uniqBy(cartItems, v => [v.product_id, v.type].join());
            }
        },
        updateCartItem(state, action) {
            /*
            This updates all array of products with the quantity, if not added then it adds with the qty.
            #[{product,quantity}]
            */
            let cartItems = ((state.data.cart.items));
            action.payload.products.forEach(product => {
                const existingProductIndex = _.findIndex(cartItems, { product_id: product.product_id });
                const prodQty = action.payload.quantity ? action.payload.quantity : product.quantity;
                if (existingProductIndex !== -1) {
                    // if product is present in cart
                    cartItems[existingProductIndex].quantity = prodQty;
                    cartItems[existingProductIndex].cost = prodQty * roundCount(cartItems[existingProductIndex].list_price);
                } else {
                    // if product is not present in cart
                    //@ts-ignore
                    cartItems.push(new Object({ ...product, quantity: prodQty, cost: prodQty * roundCount(product.list_price) }))
                }
            });
            state.data.cart.items = _.uniqBy(cartItems, v => [v.product_id, v.type].join());
        },
        prepareCart(state, action) {
            //@selected_catalog : {"method":"",product:"",type:""}

            if (action.payload.method === "add") {
                let cartItems = [];
                switch (action.payload.type) {
                    case "catalog":
                        cartItems = state.data.system.prepare.catalog;
                        state.data.system.prepare.catalog.push(action.payload.product);
                        state.data.system.prepare.catalog = _.uniqBy(cartItems, v => [v.product_id, v.type].join());
                        break;
                    case "suggest.aps":
                        cartItems = state.data.system.prepare.suggest.aps;
                        state.data.system.prepare.suggest.aps.push(action.payload.product);
                        state.data.system.prepare.suggest.aps = _.uniqWith(cartItems, _.isEqual);
                        break;
                    case "suggest.devices":
                        cartItems = state.data.system.prepare.suggest.devices;
                        state.data.system.prepare.suggest.devices.push(action.payload.product);
                        state.data.system.prepare.suggest.devices = _.uniqWith(cartItems, _.isEqual);
                        break;
                    case "suggest.mixie_server":
                        cartItems = state.data.system.prepare.suggest.mixie_server;
                        state.data.system.prepare.suggest.mixie_server.push(action.payload.product);
                        state.data.system.prepare.suggest.mixie_server = _.uniqWith(cartItems, _.isEqual);
                        break;
                }
                return;
            }
            if (action.payload.method === "remove") {
                let res = [];
                switch (action.payload.type) {
                    case "catalog":
                        res = state.data.system.prepare.catalog.filter((e) => e.product_id !== action.payload.product.product_id);
                        state.data.system.prepare.catalog = res;
                        break;
                    case "suggest.aps":
                        res = state.data.system.prepare.suggest.aps.filter((e) => e.product_id !== action.payload.product.product_id);
                        state.data.system.prepare.suggest.aps = res;
                        break;
                    case "suggest.devices":
                        res = state.data.system.prepare.suggest.devices.filter((e) => e.product_id !== action.payload.product.product_id);
                        state.data.system.prepare.suggest.devices = res;
                        break;
                    case "suggest.mixie_server":
                        res = state.data.system.prepare.suggest.mixie_server.filter((e) => e.product_id !== action.payload.product.product_id);
                        state.data.system.prepare.suggest.mixie_server = res;
                        break;
                }
                return;
            }

            if (action.payload.method === "incQty") {

                switch (action.payload.type) {
                    case "catalog":
                        let findCartItemIndex = state.data.system.prepare.catalog.findIndex((e) => e.product_id === action.payload.product.product_id);
                        if (findCartItemIndex !== -1 && (!state.data.system.prepare.catalog[findCartItemIndex].hasOwnProperty('quantity'))) {
                            state.data.system.prepare.catalog[findCartItemIndex]['quantity'] = 1;
                        }
                        let item_cost = Number(state.data.system.prepare.catalog[findCartItemIndex].list_price);// state.data.system.prepare.catalog[findCartItemIndex].quantity);

                        if (findCartItemIndex !== -1 && ((state.data.system.prepare.catalog[findCartItemIndex].quantity >= 0))) {
                            state.data.system.prepare.catalog[findCartItemIndex].quantity = state.data.system.prepare.catalog[findCartItemIndex].quantity + 1;
                            state.data.system.prepare.catalog[findCartItemIndex].cost = (state.data.system.prepare.catalog[findCartItemIndex].quantity) * (item_cost);
                            /* --- Need to cross check the logic */
                            let cartItems = state.data.system.prepare.catalog;
                            const extensionData = state.data.system.prepare.catalog[findCartItemIndex].extensionData ? state.data.system.prepare.catalog[findCartItemIndex].extensionData : [];
                            cartItems.filter(cartItem => cartItem.cui_type === 'extension').forEach(cartItem => {
                                let findCartItemIndex = state.data.system.prepare.catalog.findIndex((e) => e.product_id === cartItem.product_id);
                                let item_cost = Number(state.data.system.prepare.catalog[findCartItemIndex].list_price);
                                // Check whether product has this extentionData
                                const extensionProductIds = extensionData.map(a => a.product_id);
                                if (extensionProductIds.includes(cartItem.product_id)) {
                                    state.data.system.prepare.catalog[findCartItemIndex].quantity = state.data.system.prepare.catalog[findCartItemIndex].quantity + 1;
                                    state.data.system.prepare.catalog[findCartItemIndex].cost = (state.data.system.prepare.catalog[findCartItemIndex].quantity) * (item_cost);
                                }
                            });
                            /* --- Need to cross check the logic */
                        }
                        break;
                }
                return;
            }
            if (action.payload.method === "decQty") {
                switch (action.payload.type) {
                    case "catalog":
                        let findCartItemIndex = state.data.system.prepare.catalog.findIndex((e) => e.product_id === action.payload.product.product_id);
                        let item_cost = Number(state.data.system.prepare.catalog[findCartItemIndex].list_price);// / state.data.system.prepare.catalog[findCartItemIndex].quantity);
                        if (findCartItemIndex !== -1 && (state.data.system.prepare.catalog[findCartItemIndex].quantity > 0)) {
                            state.data.system.prepare.catalog[findCartItemIndex].quantity = state.data.system.prepare.catalog[findCartItemIndex].quantity - 1;
                            state.data.system.prepare.catalog[findCartItemIndex].cost = (state.data.system.prepare.catalog[findCartItemIndex].quantity) * (item_cost);
                            /* --- Need to cross check the logic */
                            let cartItems = state.data.system.prepare.catalog;
                            const extensionData = state.data.system.prepare.catalog[findCartItemIndex].extensionData ? state.data.system.prepare.catalog[findCartItemIndex].extensionData : [];
                            cartItems.filter(cartItem => cartItem.cui_type === 'extension').forEach(cartItem => {
                                let findCartItemIndex = state.data.system.prepare.catalog.findIndex((e) => e.product_id === cartItem.product_id);
                                let item_cost = Number(state.data.system.prepare.catalog[findCartItemIndex].list_price);
                                const extensionProductIds = extensionData.map(a => a.product_id);
                                if (extensionProductIds.includes(cartItem.product_id)) {
                                    state.data.system.prepare.catalog[findCartItemIndex].quantity = state.data.system.prepare.catalog[findCartItemIndex].quantity - 1;
                                    state.data.system.prepare.catalog[findCartItemIndex].cost = (state.data.system.prepare.catalog[findCartItemIndex].quantity) * (item_cost);
                                }
                            });
                            /* --- Need to cross check the logic */
                        }
                        break;

                }
                return;
            }
            state.data.system.prepare.catalog = action.payload;

        },
        setCart(state, action) {
            // Convert the quantity to number
            const updatedCartItems = action.payload.map(item => ({
                ...item,
                quantity: +item.quantity // convert to number
            }));
            state.data.cart.items=updatedCartItems
        },
        clearCartBoq(state,){
            state = {...initialData};
        }
       
    },
    extraReducers(builder) {
        builder.addCase(setProp, (state, action: setPropT) => {
            if (action.payload.slice === cartBoqSlice.name) {
                _.set(state.data, action.payload.key, action.payload.value);
            }
        })
    }
})
export default cartBoqSlice.reducer;
export const { removeCartItem, addCartItem, updateCartItem, prepareCart, setCart, clearCartBoq } = cartBoqSlice.actions;

export const totalCost = (state:RootState['cartBoq']) => _.sumBy(state.data.cart.items, c => roundCount(c.cost));