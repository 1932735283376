import {
    Label,
    TextArea,
    Typography
} from "@nokia-csf-uxr/ccfk";
import TextInput, {
    TextInputLabelContent
} from "@nokia-csf-uxr/ccfk/TextInput";
import SelectBar from "Components/Elements/SelectBar";
// import {
//     handleGetKeyFromObj,
//     handleGetValueFromArray,
//     useRenderState, useValidate
// } from "Components/Logics/ensoCommonFunctions";
import {
    handleGetKeyFromObj,
    useRenderState, useValidate
} from "Components/Logics/ensoCommonFunctions";
import { handleExceptionR6InternalLogic } from "Components/Logics/systemLogics";
import {
    STATUS,
    countryList,
    currencyList,
    market,
    salesChannel
} from "Components/Misc/CaseDetails_System";
import { systemStyle } from "Constants/StyleLayer";
import { convertOptions } from "Constants/convertor/oot";
import { setProp } from "Store/Actions";
import {
    setData
} from "Store/contentState/caseDetails/caseDetailsSlice";
import { RootState } from "Store/mainStore";
// import React, { useMemo ,memo} from "react";
import PropTypes from 'prop-types';
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

interface ICaseDetailsOptions {
    selectedFields: Array<any> | any
}
const CaseDetailsOptions = (props: ICaseDetailsOptions) => {
    const isFormValid = useValidate();
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const caseDetailsSelector = useSelector((state: RootState) => state.caseDetails);
    const findCaseSelector = useSelector((state: RootState) => state.findCase);
    const themeSelector = useSelector((state: RootState) => state.theme);
    const authSelector = useSelector((state: RootState) => state.authSlice);
    // const toolSelection = useSelector((state: RootState) => state.toolSelection);
    // const contract_duration = caseDetailsSelector.data.contract_duration;
    const loadedTool = authSelector.OOT.status === STATUS.IDLE && authSelector.OOT?.data.hasOwnProperty('case_name') && authSelector.OOT?.data.case_name ? "OOT"
        : "None";
    const hideSalesChannel = useMemo(() => {
        return handleExceptionR6InternalLogic(state);
    }, [findCaseSelector.data.caseType]);

    const marketPlaceIsDisabled = () => {
        let isdisabled = authSelector.OOT.data.market.default !== "";
        if(authSelector.OOT.data.market.default && !_.includes(_.keys(authSelector.OOT.data.market.options),authSelector.OOT.data.market.default)) {
            isdisabled = false;
        }
        return isdisabled;
    }

    const currencyIsDisabled = () => {
        let isdisabled = authSelector.OOT.data.currency.default !== "";
        if(authSelector.OOT.data.currency.default && !_.includes(_.keys(authSelector.OOT.data.currency.options),authSelector.OOT.data.currency.default)) {
            isdisabled = false;
        }
        return isdisabled;
    }

    return (
        <>
            <Label className="mb" id="sales_channel"
                variant="vertical-layout"
                maxWidth={true}
                hidden={!useRenderState("form", "sales_channel")}
                data-flag={(loadedTool === "OOT" && props.selectedFields.includes('sales_channel')) ? "right" : "left"}
            >
                <TextInputLabelContent required>Sales channel</TextInputLabelContent>
                <SelectBar
                    handOnSelect={(e) => {

                    }}
                    key={"SC"}
                    isDisabled={(loadedTool === "OOT" || hideSalesChannel) ? true : false}
                    isOpen={
                        themeSelector.data.select.isOpen.caseDetails_sales_channel
                    }
                    options={convertOptions({
                        ootHook: authSelector.OOT.data.sales_channel,
                        type: loadedTool,
                        defaultList: salesChannel,
                        idSrc: "sales_channel",
                        redX: {
                            dispatch: dispatch,
                            selector: caseDetailsSelector,
                            auth: authSelector,
                        }, //pass the redux
                    })}
                    renderValue={handleGetKeyFromObj(
                        salesChannel,
                        caseDetailsSelector.data.sales_channel
                    )} //truevalue
                    optionPath="id"
                    displayKey={"value"}

                    setProperty={{
                        sliceProperty: "sales_channel",
                        themeProperty: "caseDetails_sales_channel",
                    }}
                />
            </Label>
            <Label className="mb" maxWidth={true} id="account"
                variant="vertical-layout"
                data-flag={"left"}
                hidden={!useRenderState("form", "account")}
            >
                <TextInputLabelContent required>Account</TextInputLabelContent>
                <SelectBar
                    key={"account"}
                    isDisabled={
                        loadedTool === "OOT" ||
                        caseDetailsSelector.data.sales_channel === ""
                    }
                    isOpen={
                        themeSelector.data.select.isOpen.caseDetails_account
                    }
                    options={authSelector.system.account}
                    renderValue={handleGetKeyFromObj(
                        authSelector.system.account,
                        caseDetailsSelector.data.account
                    )} //truevalue
                    optionPath="id"
                    displayKey={"value"}
                    setProperty={{
                        sliceProperty: "account",
                        themeProperty: "caseDetails_account",
                    }}
                />
            </Label>
            <Label className="mb" maxWidth={true} id="distributor"
                variant="vertical-layout"
                data-flag={(loadedTool === "OOT" && props.selectedFields.includes("distributor")) ? "right" : "left"}
                hidden={
                    !useRenderState("form", "distributor") ||
                    !themeSelector.data.select.isVisible
                        .caseDetails_distributor ||
                    !caseDetailsSelector.data.sales_channel
                }
            >
                <TextInputLabelContent required>Distributor</TextInputLabelContent>
                <SelectBar
                    key={"Dist"}
                    isDisabled={
                        loadedTool === "OOT" ? true : false ||
                            (caseDetailsSelector.data.distributor !== ''
                                && caseDetailsSelector.data.distributor === caseDetailsSelector.data.account)
                    }
                    isOpen={
                        themeSelector.data.select.isOpen.caseDetails_distributor
                    }
                    options={authSelector.system.account}
                    renderValue={handleGetKeyFromObj(
                        authSelector.system.distributor,
                        caseDetailsSelector.data.distributor
                    )} //truevalue
                    optionPath="id"
                    displayKey={"value"}
                    setProperty={{
                        sliceProperty: "distributor",
                        themeProperty: "caseDetails_distributor",
                    }}
                />
            </Label>
            <Label className="mb" maxWidth={true} id="indirect_resellers"
                variant="vertical-layout"
                data-flag={(loadedTool === "OOT" && props.selectedFields.includes("indirect_reseller")) ? "right" : "left"}
                hidden={
                    !useRenderState("form", "indirect_resellers") || !caseDetailsSelector.data.sales_channel || !themeSelector.data.select.isVisible
                        .caseDetails_indirect_resellers
                }
            >
                <TextInputLabelContent required>
                    Indirect resellers
                </TextInputLabelContent>
                {loadedTool === "OOT" ? (
                    <SelectBar
                        isDisabled={loadedTool === "OOT" ? true : false}
                        key={"IR"}
                        isOpen={
                            themeSelector.data.select.isOpen
                                .caseDetails_indirect_resellers
                        }
                        renderValue={handleGetKeyFromObj(
                            authSelector.system.indirect_resellers,
                            caseDetailsSelector.data.indirect_resellers
                        )} //truevalue
                        optionPath="id"
                        displayKey={"value"}
                        options={authSelector.system.indirect_resellers}
                        setProperty={{
                            sliceProperty: "indirect_resellers",
                            themeProperty: "caseDetails_indirect_resellers",
                        }}
                    />
                ) : (
                    <>
                        <TextInput
                            error={isFormValid.length > 0}
                            // errorMessage= 'case name cannot be empty'
                            id="indirect_resellers"
                            style={{ width: "100%" }}
                            value={caseDetailsSelector.data.indirect_resellers}

                            // disabled={loadedTool === "OOT" ? true : false}
                            onChange={(e) =>
                                dispatch(
                                    setData({
                                        key: "indirect_resellers",
                                        value: e.target.value,
                                    })
                                )
                            }
                        />
                    </>
                )}
            </Label>
            <Label className="mb" maxWidth={true} id="resellers"
                variant="vertical-layout"
                data-flag={(loadedTool === "OOT" && props.selectedFields.includes("reseller")) ? "right" : "left"}

                hidden={!useRenderState("form", "resellers") || !caseDetailsSelector.data.sales_channel || !themeSelector.data.select.isVisible
                    .caseDetails_resellers}
            >
                <TextInputLabelContent required>Resellers</TextInputLabelContent>
                <SelectBar
                    key={"IR"}
                    isDisabled={
                        loadedTool === "OOT" ? true : false ||
                            (caseDetailsSelector.data.resellers !== ''
                                && caseDetailsSelector.data.resellers === caseDetailsSelector.data.account)
                    }

                    isOpen={
                        themeSelector.data.select.isOpen.caseDetails_resellers
                    }
                    renderValue={handleGetKeyFromObj(
                        authSelector.system.resellers,
                        caseDetailsSelector.data.resellers
                    )} //truevalue
                    optionPath="id"
                    displayKey={"value"}
                    options={authSelector.system.account}
                    setProperty={{
                        sliceProperty: "resellers",
                        themeProperty: "caseDetails_resellers",
                    }}
                />
            </Label>
            <Label className="mb" id="endCustomer"
                variant="vertical-layout"
                data-flag={"left"}
                maxWidth={true}
                hidden={
                    !useRenderState("form", "endCustomer") || !caseDetailsSelector.data.sales_channel || !themeSelector.data.select.isVisible
                        .caseDetails_endCustomer
                }
            >
                <TextInputLabelContent required>End customer</TextInputLabelContent>
                <SelectBar
                    key={"EC"}
                    isOpen={
                        themeSelector.data.select.isOpen.caseDetails_endCustomer
                    }
                    isDisabled={
                        !caseDetailsSelector.data.account ||
                        loadedTool === "OOT" ||
                        themeSelector.data.select.isDisabled
                            .caseDetails_endCustomer 

                    }
                    renderValue={handleGetKeyFromObj(
                        authSelector.system.endCustomer,
                        caseDetailsSelector.data.endCustomer
                    )} //truevalue
                    optionPath="id"
                    displayKey={"value"}
                    options={authSelector.system.endCustomer}
                    setProperty={{
                        sliceProperty: "endCustomer",
                        themeProperty: "caseDetails_endCustomer",
                    }}
                />
            </Label>
            <Label className={`mb`} maxWidth={true} id="caseName"
                variant="vertical-layout"
                data-flag={((loadedTool === "OOT" && props.selectedFields.includes('case_name')) || (loadedTool !== "OOT")) ? "right" : "left"
                }
                hidden={!useRenderState("form", "caseName")}
            >
                <TextInputLabelContent required>Case name</TextInputLabelContent>
                {loadedTool === "OOT" ? (
                    <Typography typography="BODY">{caseDetailsSelector.data.caseName}</Typography>
                ):(
                    <TextInput
                    error={
                        isFormValid.length > 0 &&
                        isFormValid.includes("caseName")
                    }
                    // errorMessage= 'case name cannot be empty'
                    id={"caseName"}
                    style={{ width: "100%" }}
                    value={caseDetailsSelector.data.caseName}
                    // disabled={loadedTool === "OOT" ? true : false}
                    onChange={(e) =>
                        dispatch(
                            setData({ key: "caseName", value: e.target.value })
                        )
                    }
                />
                )}
                
            </Label>
            <Label className="mb" maxWidth={true} id="market"
                variant="vertical-layout"
                data-flag={((loadedTool === "OOT" && props.selectedFields.includes("market"))) ? "right" : "left"}
                hidden={!useRenderState("form", "market")}
            >
                <TextInputLabelContent required>Market</TextInputLabelContent>
                <SelectBar
                    key={"MKT"}
                    isOpen={
                        themeSelector.data.select.isOpen.caseDetails_market
                    }
                    isDisabled={marketPlaceIsDisabled()}
                    options={convertOptions({
                        ootHook: authSelector.OOT.data.market, //entrypoint where list are present in OOT RESPOSNSE
                        type: loadedTool, // which list to load oot | default
                        defaultList: market, //fallback list
                        idSrc: "market",
                        redX: {
                            dispatch: dispatch,
                            selector: caseDetailsSelector,
                            auth: authSelector,
                        }, //pass the redux
                    })}
                    renderValue={handleGetKeyFromObj(
                        market,
                        caseDetailsSelector.data.market
                    )} //truevalue
                    optionPath="id"
                    displayKey={"value"}
                    setProperty={{
                        sliceProperty: "market",
                        themeProperty: "caseDetails_market",
                    }}
                />
            </Label>
            <Label id="country" className="mb"
                variant="vertical-layout"
                data-flag={"right"}
                maxWidth={true}
                hidden={!useRenderState("form", "country")}
            >
                <TextInputLabelContent required>
                    Network location country
                </TextInputLabelContent>
                <SelectBar
                    key={"NLC"}
                    isOpen={
                        themeSelector.data.select.isOpen.caseDetails_country
                    }
                    options={authSelector.system.countryList}
                    renderValue={handleGetKeyFromObj(
                        countryList,
                        caseDetailsSelector.data.country,
                        "code",
                        "name"
                    )} //truevalue
                    optionPath="code"
                    displayKey={"name"}
                    setProperty={{
                        sliceProperty: "country",
                        themeProperty: "caseDetails_country",
                    }}
                />
            </Label>
            <Label id="shipping_country"
                className="mb"
                variant="vertical-layout"
                data-flag={"right"}
                maxWidth={true}
                hidden={!useRenderState("form", "shipping_country")}
            >
                <TextInputLabelContent required>
                    Shipping country
                </TextInputLabelContent>
                <SelectBar
                    key={"SC"}
                    isOpen={
                        themeSelector.data.select.isOpen
                            .caseDetails_shipping_country
                    }
                    options={authSelector.system.countryList}
                    renderValue={handleGetKeyFromObj(
                        countryList,
                        caseDetailsSelector.data.shipping_country,
                        "code",
                        "name"
                    )} //truevalue
                    optionPath="code"
                    displayKey={"name"}
                    setProperty={{
                        sliceProperty: "shipping_country",
                        themeProperty: "caseDetails_shipping_country",
                    }}
                />
            </Label>
            <Label id="network_id"
                className="mb"
                variant="vertical-layout"
                data-flag={"right"}
                maxWidth={true}
                hidden={!useRenderState("form", "network_id")}
            >
                <TextInputLabelContent required>
                    Network name
                </TextInputLabelContent>
                {

                    (authSelector.system.networkids.length !== 0) ? (   <SelectBar
                        key={"SC"}
                        isOpen={
                            themeSelector.data.select.isOpen
                                .caseDetails_network_id
                        }
                        options={authSelector.system.networkids}
                        renderValue={handleGetKeyFromObj(
                            authSelector.system.networkids,
                            caseDetailsSelector.data.network_id,
                        )} //truevalue
                        optionPath="id"
                        displayKey={"value"}
                        setProperty={{
                            sliceProperty: "network_id",
                            themeProperty: "caseDetails_network_id",
                        }}
                    />): (<TextInput
                        value={caseDetailsSelector.data.network_id}
                        variant={"outlined"}
                        placeholder={"Network Name"}
                            onChange={(e) => {
                            dispatch(setProp({ slice: "caseDetails", key: `network_id`, value: e.target.value.trim() }))
                        }}
                      ></TextInput>)
                }
             
            </Label>
        
            <Label id="currency"
                variant="vertical-layout"
                className="mb"
                data-flag={(loadedTool === "OOT" && props.selectedFields.includes("currency")) ? "right" : "left"}
                maxWidth={true}
                hidden={!useRenderState("form", "currency")}
            >
                <TextInputLabelContent required>Currency</TextInputLabelContent>
                <SelectBar
                    key={"CUR"}
                    isOpen={
                        themeSelector.data.select.isOpen.caseDetails_currency
                    }
                    isDisabled={currencyIsDisabled()}
                    optionPath="id"
                    displayKey={"value"}
                    setProperty={{
                        themeProperty: "caseDetails_currency",
                        sliceProperty: "currency",
                    }}
                    options={convertOptions({
                        ootHook: authSelector.OOT.data.currency, //entrypoint where list are present
                        type: loadedTool, // which list to load oot | default
                        defaultList: currencyList, //fallback list
                        idSrc: "currency",
                        redX: {
                            dispatch: dispatch,
                            selector: caseDetailsSelector,
                            auth: authSelector,
                        }, //pass the redux
                    })}
                    renderValue={handleGetKeyFromObj(
                        currencyList,
                        caseDetailsSelector.data.currency
                    )}
                />
            </Label>

            <div
                data-flag={"right"}
                hidden={loadedTool !== "OOT"}
                style={systemStyle.genrateGridCols(2)}
                id=""
            >
                <div
                    id="summary"
                    className="mb"
                    hidden={!useRenderState("form", "summary")}
                >
                    <Label >
                        <TextInputLabelContent>
                            Case summary
                        </TextInputLabelContent>
                    </Label>
                    <TextArea
                        value={caseDetailsSelector.data.summary}
                        placeholder="Offer summary"
                        onChange={(e) =>
                            dispatch(
                                setData({ key: "summary", value: e.target.value })
                            )
                        }
                    ></TextArea>
                </div>
                <div
                    id="co_owners"
                    className="mb"
                    hidden={!useRenderState("form", "co_owners")}
                >
                    <Label>
                        <TextInputLabelContent>Co-owners</TextInputLabelContent>
                    </Label>
                    <TextArea
                        value={caseDetailsSelector.data.co_owner}
                        placeholder="Enter email comma - separated"
                        onChange={(e) =>
                            dispatch(
                                setData({ key: "co_owner", value: e.target.value })
                            )
                        }
                    ></TextArea>
                </div>
            </div>

        </>
    )
}

export default React.memo(CaseDetailsOptions)
CaseDetailsOptions.propTypes = {
    selectedFields: PropTypes.array
}