// import { systemStyle } from 'Constants/StyleLayer'
import React from 'react'
import OptionsFrame from 'Components/Content/Frames/OptionsFrame'
import ViewFrame from 'Components/Content/Frames/ViewFrame'
import Options from 'Components/Content/configurationArea/Configurator/Options'
import View from 'Components/Content/configurationArea/Configurator/View'
import ReviewView from 'Components/Content/configurationArea/Review/View'
import Frame from 'Components/Content/Frames/Frame';
import { useSelector } from 'react-redux'
import { RootState } from 'Store/mainStore'
import NDACPlanner from './NDACRefereceSolution/NDACPlanner'
import _ from "lodash";

const ConfiguratorIndex = () => {
    const businessSelector = useSelector((state: RootState) => state.business);
    const configstepperSelector = useSelector((state: RootState) => state.config_stepper);
    const stepname = (_.find(configstepperSelector.data.stepper_data,{stepname : configstepperSelector.data.active_step})).name;
    return (
        <>
            <Frame>
                <OptionsFrame title={stepname}>
                {/* {
                    businessSelector.data.options.PTDAC.caseIndustry === 'reference_solution' &&
                    configstepperSelector.data.active_step === 'connectivity' ? (
                        <NDACPlanner></NDACPlanner>
                    ) : <Options />
                } */}
                <Options />
                </OptionsFrame>
                <ViewFrame>
                {configstepperSelector.data.active_step === "review"?
                <ReviewView/>:
                    <View />}
                </ViewFrame>
            </Frame>
        </>
    )
}

export default ConfiguratorIndex