import React, { lazy, Suspense } from "react";
import OptionsFrame from "Components/Content/Frames/OptionsFrame";
import ViewFrame from "Components/Content/Frames/ViewFrame";
// import Options from 'Components/Content/configurationArea/Review/Options'
import View from "Components/Content/configurationArea/Review/View";
import Frame from "Components/Content/Frames/Frame";
import { RootState } from "Store/mainStore";
import { useSelector } from "react-redux";
import { Skeleton } from "@nokia-csf-uxr/ccfk";
const ReviewStepList = lazy(() => import("./ReviewStepList"));

const ReviewIndex = () => {
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );
  return (
    <>
      {configstepperSelector.data.active_step === "review" ? (
        <Suspense fallback={<Skeleton />}>
          <ReviewStepList />
        </Suspense>
      ) : (
        <>
          <Frame>
            <OptionsFrame title="Review & Submit">
              <ReviewStepList />
            </OptionsFrame>
            <ViewFrame>
              <View />
            </ViewFrame>
          </Frame>
        </>
      )}
    </>
  );
};

export default ReviewIndex;
