import React, { useCallback } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import CONFIG_API from "Constants/services/configurator";
import { setError } from "Store/general/auth";
import SelectFields from "../DynamicField/selectfield";
import MultiSelect from "../DynamicField/multiselect";
import RadioButtonField from "../DynamicField/radiobuttonfield";
import CheckboxField from "../DynamicField/checkboxfield";
import TextField from "../DynamicField/textfield";
import RangeField from "../DynamicField/rangefield";
import SpinnerField from "../DynamicField/spinnerfield";
import Multicheckboxs from "../DynamicField/multicheckbox";
import Loader from "Components/Elements/Loader";
import { TextInputLabelContent } from "@nokia-csf-uxr/ccfk/TextInput";
import MarkupField from "../DynamicField/markupfield";

export const useDynamicElementMap = () => {
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);

  /** errorMessageText */
  const errorMessageText = (text) => {
    return (
      <div className="fztuMa">
        <span
          role="alert"
          className="wBUPn"
          style={{ color: "#C91514", fontSize: "13px" }}
        >
          {text.replace(/_/g, " ").toLowerCase() + " cannot be empty"}
        </span>
      </div>
    );
  };

  /** getDynamicElement */
  const getDynamicElement = (type, config) => {
    const {
      isLoading,
      data,
      optionsData,
      optionsDataList,
      updateDispatchStore,
      fieldsDisableEnable,
      allRefs,
      getReturnValue,
      radioExpandPanel,
      updateRadioButton,
      expandVisibility,
      displayLayout,
      dataStore
    } = config;

    const active_step = configstepperSelector.data.active_step;
    const sliceProperty = config.sliceProperty ? `${config.sliceProperty}` : undefined

    let min = 0, max = 0, steps = 0;

    if(type === "number"){
      min = dynamicSelector.data.elementMMS[active_step][data[0]] && dynamicSelector.data.elementMMS[active_step][data[0]]['min'] ? dynamicSelector.data.elementMMS[active_step][data[0]]['min'] : 1;
      max = dynamicSelector.data.elementMMS[active_step][data[0]] && dynamicSelector.data.elementMMS[active_step][data[0]]['max'] ? dynamicSelector.data.elementMMS[active_step][data[0]]['max'] : 1;
      steps = dynamicSelector.data.elementMMS[active_step][data[0]] && dynamicSelector.data.elementMMS[active_step][data[0]]['steps'] ? dynamicSelector.data.elementMMS[active_step][data[0]]['steps'] : 1;
      
      // min = (min !== undefined && data[1]['min'] && (data[1]['min']).toString().length > 0) ? data[1]["min"] : 0;
      // max = (max !== undefined && data[1]['max'] && (data[1]['max']).toString().length > 0) ? data[1]["max"] : 0;
      // steps = (steps !== undefined && data[1]['steps'] && (data[1]['steps']).toString().length > 0) ? data[1]["steps"] : 0;
    }

    const elementsMapping = new Map([
      [
        "select",
        () =>
          data[1]["multiple"] ? (
            <MultiSelect
              optionsData={optionsData}
              active_step={configstepperSelector.data.active_step}
              data={data}
              updateDispatchStore={updateDispatchStore}
              isDisabled={fieldsDisableEnable}
              errorMessageText={errorMessageText}
              ref={allRefs.current}
              dataStore={dataStore}
            />
          ) : (
            <SelectFields
              optionsData={optionsData}
              active_step={configstepperSelector.data.active_step}
              data={data}
              updateDispatchStore={updateDispatchStore}
              isDisabled={fieldsDisableEnable}
              errorMessageText={errorMessageText}
              ref={allRefs.current}
              getReturnValue={getReturnValue}
              dataStore={dataStore}
              sliceProperty={sliceProperty}
            />
          ),
      ],
      [
        "radio",
        () =>
          optionsData[configstepperSelector.data.active_step] &&
          optionsData[configstepperSelector.data.active_step][data[0]] &&
          _.isEmpty(
            optionsData[configstepperSelector.data.active_step][data[0]]
          ) ? (
            <>No Items are available based on selected parameters</>
          ) : (
            <RadioButtonField
              optionsData={optionsData}
              active_step={configstepperSelector.data.active_step}
              data={data}
              updateDispatchStore={updateDispatchStore}
              isDisabled={fieldsDisableEnable}
              errorMessageText={errorMessageText}
              expandVisibility={radioExpandPanel}
              updateRadioButton={updateRadioButton}
              dataStore={dataStore}
            />
          ),
      ],
      [
        "checkbox",
        () =>
          data[1].multiple &&
          !data[1].options &&
          !_.isEmpty(optionsDataList) &&
          data[1].id === optionsDataList["id"] ? (
            isLoading ? (
              <Loader />
            ) : (
              !_.isEmpty(optionsDataList) && (
                <Multicheckboxs
                  optionsData={optionsDataList["data"]}
                  active_step={configstepperSelector.data.active_step}
                  data={data}
                  updateDispatchStore={updateDispatchStore}
                  isDisabled={fieldsDisableEnable}
                  errorMessageText={errorMessageText}
                  expandVisibility={expandVisibility}
                  dataStore={dataStore}
                />
              )
            )
          ) : (
            <CheckboxField
              optionsData={optionsData}
              active_step={configstepperSelector.data.active_step}
              data={data}
              updateDispatchStore={updateDispatchStore}
              isDisabled={fieldsDisableEnable}
              errorMessageText={errorMessageText}
              displayLayout={displayLayout}
              dataStore={dataStore}
            />
          ),
      ],
      [
        "text",
        () => (
          <TextField
            data={data}
            active_step={configstepperSelector.data.active_step}
            updateDispatchStore={updateDispatchStore}
            isDisabled={fieldsDisableEnable}
            errorMessageText={errorMessageText}
            ref={allRefs.current}
            dataStore={dataStore}
          />
        ),
      ],
      [
        "number",
        () => (
          <SpinnerField
            data={data}
            active_step={configstepperSelector.data.active_step}
            updateDispatchStore={updateDispatchStore}
            isDisabled={fieldsDisableEnable}
            errorMessageText={errorMessageText}
            ref={allRefs}
            dataStore={dataStore}
            min={min}
            max={max}
            steps={steps}
          />
        ),
      ],
      [
        "range",
        () => (
          <RangeField
            data={data}
            active_step={configstepperSelector.data.active_step}
            updateDispatchStore={updateDispatchStore}
            isDisabled={fieldsDisableEnable}
            errorMessageText={errorMessageText}
            ref={allRefs.current}
            dataStore={dataStore}
          />
        ),
      ],
      ["label", () => <TextInputLabelContent>{data[0]}</TextInputLabelContent>],
      [
        "markup",
        () => data[1]["subtype"] !== 'chart' && (
          <MarkupField
            data={data}
            active_step={configstepperSelector.data.active_step}
            updateDispatchStore={updateDispatchStore}
            isDisabled={fieldsDisableEnable}
            errorMessageText={errorMessageText}
            ref={allRefs.current}
          />
        ),
      ],
    ]);
    return elementsMapping.get(type);
  };

  return {
    getDynamicElement
  };

};

