//@ts-nocheck
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import authReducer from "Store/general/auth";
import cartBoqSlice from "Store/contentState/cartBoqSlice";
import caseDetailsReducer from "Store/contentState/caseDetails/caseDetailsSlice";
// import applicationsSlice from "Store/contentState/configurationArea/PTDAC/applicationsSlice";
// import connectivitySlice from "Store/contentState/configurationArea/PTDAC/connectivitySlice";
// import edgeSlice from "Store/contentState/configurationArea/PTDAC/edgeSlice";
import businessSlice from "Store/contentState/configurationArea/businessSlice";
import devicesSlice from "Store/contentState/configurationArea/devicesSlice";
import reviewSlice from "Store/contentState/configurationArea/reviewSlice";
import servicesSlice from "Store/contentState/configurationArea/servicesSlice";
import warrantySlice from "Store/contentState/configurationArea/warrantySlice";
import findCaseSliceReducer from "Store/contentState/caseDetails/findCaseSlice";
import configuration_stepperSlice from "./contentState/configuration_stepperSlice";
import stateMachineSlice from "Store/contentState/stateMachine";
import toolSelectionReducer from "Store/contentState/caseDetails/toolSelection";
import { myLoggingMiddleware } from "Store/middleware";
import themeReducer from "Store/general/theme";
import { batchGroupBy } from "Utils";
// import { myLoggingMiddleware } from "./middleware";
import undoable, { groupByActionTypes } from 'redux-undo';
import dynamicSlice from "Store/contentState/configurationArea/dynamicSlice";

// Create an undoable cart reducer using groupByActionTypes
const undoableCartReducer = undoable(cartBoqSlice, {
  filter: groupByActionTypes(['cartBoq/addCartItem', 'cartBoq/updateCartItem']),
  // groupBy:groupByActionTypes(['cartBoq/addCartItem', 'cartBoq/updateCartItem']),
  groupBy:batchGroupBy.init([['cartBoq/addCartItem', 'cartBoq/updateCartItem']]),
  debug:true,
});
export const store = configureStore({
  reducer: {
    //Home//
    authSlice: authReducer,
    findCase: findCaseSliceReducer,
    caseDetails: caseDetailsReducer,
    toolSelection: toolSelectionReducer,
    //Configuration Area//
    business: businessSlice,
    devices: devicesSlice,
    review: reviewSlice,
    services: servicesSlice,
    warranty: warrantySlice,
    //Configuration Area - PTDAC//
    // applications: applicationsSlice,
    // connectivity: connectivitySlice,
    // edge: edgeSlice,
    //CART//
    cartBoq: (cartBoqSlice),
    undoCartBoq: undoableCartReducer,
    //THEME - GLOBAL//
    theme: themeReducer,
    //State-Machine - Global//
    stateMachine: stateMachineSlice,
    config_stepper : configuration_stepperSlice,
    //RTK-Query -apis
    // [CUIAPIS.reducerPath]: CUIAPIS.reducer,

    //Configurator Dynamic//
    dynamic: dynamicSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(myLoggingMiddleware),
});
//@ts-ignore
window.store = store;
setupListeners(store.dispatch);
export type AppDispatch = typeof store.dispatch; // Here we export the store's dispatch type
export type RootState = ReturnType<typeof store.getState>; // H
