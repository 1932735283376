import { FieldSet, Label, RadioButton } from "@nokia-csf-uxr/ccfk";
import { FieldSetContent, Legend } from "@nokia-csf-uxr/ccfk/FieldSet";
import { RadioButtonLabelContent } from "@nokia-csf-uxr/ccfk/RadioButton";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { systemStyle } from "Constants/StyleLayer";
import { setExtensionTool } from "Store/contentState/caseDetails/toolSelection";
import { extensionOfferTools } from "Components/Misc/CaseDetails_System";
import { ToolSelection as TStype } from "Store/contentState/ts";
import { RootState } from "Store/mainStore";

export const ToolSelection = () => {
  const dispatch = useDispatch();
  const toolSelection:TStype = useSelector((state:RootState) => state.toolSelection);
  const handleSelection = (tool) => {
    dispatch(setExtensionTool(tool));
  };

  return (
    <>
      <FieldSet >
        <Legend>Select tool for Extension offer</Legend>
        <FieldSetContent>
          <div style={systemStyle.flex({})}>
            <div>
              <Label
                title={extensionOfferTools.MPW_GSM}
                onClick={() => handleSelection(extensionOfferTools.MPW_GSM)}
              >
                <RadioButton
                  checked={
                    toolSelection.data.extensionTool ===
                    extensionOfferTools.MPW_GSM
                  }
                  onChange={()=>{}}
                  
                />
                <RadioButtonLabelContent>
                  MPW-S
                </RadioButtonLabelContent>
              </Label>
            </div>
            <div>
              <Label onClick={() => handleSelection(extensionOfferTools.NDAC)} title={extensionOfferTools.NDAC}>
                <RadioButton
                  checked={
                    toolSelection.data.extensionTool ===
                    extensionOfferTools.NDAC
                  }
                  onChange={()=>{}}
                  
                />
                <RadioButtonLabelContent>
                 NDAC
                </RadioButtonLabelContent>
              </Label>
            </div>
          </div>
        </FieldSetContent>
      </FieldSet>
    </>
  );
};
