import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "Components/Misc/CaseDetails_System";
import { setProp } from "Store/Actions";
import { setPropT } from 'Store/Actions/ts';
import setObj from "lodash/set";
const initialData = {
    element: {},
    dataSet: {},
    callBack: {},
    validationDetails : {},
    sid: "",
    boq:[],
    actionNext: false,
    tabSelect: 'Full Solutions',
    reference_solution: {
        solution: "NDAC", 
        commercial_model: "Opex",
        contract_duration: 1, 
        ndacPlanner:{},
        linkedPlanner:{}
    },
    selected_ndac_services : [],
    finalCombined_ndac_services : [],
    elementMMS: {},
    undoBoq:[],
    pageFeedbacks:[],
    callbackdefaults : {},
    eot_response_syncoffer : [],
    setTargetFields: {},
    setAccessoriesButton: true,
    localStates : {
        setOptionsData: {},
        setOptionsDataList : {},
        setSearchButtonStatus : false,
        setIsLoading: false,
        setProdCatalogApiLoad : false,
        setProductDataList: {},
        setConsumptionGraph: false
    },
    dataFromPackage:{},
    modal:{},
    stepFormInputs:{},
    package_options_count : 0
};
const dynamicSlice = createSlice({
    initialState: {
        data: initialData,
        status: STATUS.IDLE,
    },
    name: 'dynamic',
    reducers: {
        setElement(state, action) {
            state.data.element = action.payload
        },
        setArrayElement(state,action){
            if (action.payload.method === "add") {
                state.data.element[action.payload.activeStep][action.payload.useCase].push(action.payload.data);
            }
            if (action.payload.method === "remove") {
                state.data.element[action.payload.activeStep][action.payload.useCase] = state.data.element[action.payload.activeStep][action.payload.useCase].filter((e) => e.key !== action.payload.data.key);
            }
        },
        setCallBack(state,action){
            state.data.callBack = action.payload
        },
        resetDynamicStep(state,){
            state.data = {...initialData}
            state.status = STATUS.IDLE;
        },
        setQuantityList(state,action){
            state.data.element[action.payload.activeStep][action.payload.useCase][action.payload.selectName] = state.data.element[action.payload.activeStep][action.payload.useCase][action.payload.selectName].map((e) => e.id == action.payload.device.id ? action.payload.device : e);
        },
        setSelectedNdacServices(state, action){
            // console.log(action.payload, 'ndac payload')
            if(action.payload.method === "add"){
                const value = action.payload.data
                
                state.data.selected_ndac_services.push(value)
            }
        },
        setDataSet(state,action) {
            let newData = {...state.data.dataSet};
            for (let key in action.payload) {
                if((Object.keys(newData)).includes(key)) {
                    newData[key] = action.payload[key];
                }
            }
            if(action.payload.method === "remove"){
                state.data.selected_ndac_services = state.data.selected_ndac_services.filter((e) => e.product_id !== action.payload.data)
            }
        },
        // localstates used from options moved here
        setState(state,action) {
          state.data.localStates = action.payload;
        },

        setCount_of_data(state,action) {
            let newData = {...state.data.element};
            for (let step in action.payload) {
                if((Object.keys(newData)).includes(step)) {
                    for (let key in action.payload[step]) {
                        newData[step][key] = action.payload[step][key];
                    }
                }
            }
            state.data.element = {...newData};
        }
        
        
        //  setRef(allRefs, item_id, value) {
        //     allRefs.current[item_id] = value;
        //   },
        
        // getRef(allRefs, item_id) {
        // return allRefs.current[item_id];
        // }
    },
    extraReducers(builder) {
        builder.addCase(setProp, (state, action: setPropT) => {
            if (action.payload.slice === dynamicSlice.name) {
                setObj(state.data, action.payload.key, action.payload.value);
            }
        })
    }
});

export default dynamicSlice.reducer;
export const {setElement, setArrayElement, setCallBack, resetDynamicStep,setQuantityList, setSelectedNdacServices,setDataSet,setCount_of_data } = dynamicSlice.actions;
