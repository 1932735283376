import React, { useEffect, useState, Fragment } from "react";
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import { TextInputLabelContent } from "@nokia-csf-uxr/ccfk/TextInput";
import { RadioButton, Label, HorizontalDivider } from "@nokia-csf-uxr/ccfk";
import { RadioButtonLabelContent } from "@nokia-csf-uxr/ccfk/RadioButton";
import ExpansionPanelsContainer, {
    ExpansionPanel,
    ExpansionPanelTitle,
    ExpansionPanelHeader,
    ExpansionPanelBody,
    ExpansionPanelButton
} from '@nokia-csf-uxr/ccfk/ExpansionPanels';
import { setProp } from "Store/Actions";

import TOKENS from '@nokia-csf-uxr/nokia-design-system-tokens/js/tokens.es6';
import { HelpText } from "../hooks/useHelpText";

interface DataList {
    data: Array<any>,
    optionsData: object,
    active_step: string,
    updateDispatchStore?: Function,
    isDisabled: boolean,
    checkErrorStatus?: Function,
    errorMessageText?: Function,
    expandVisibility: boolean,
    updateRadioButton?: Function,
    dataStore?:Object
}

const PADDING = `${TOKENS.SPACING_MEDIUM} ${TOKENS.SPACING_LARGE}`;

const RadioButtonField = (props: DataList) => {
    // const dynamicSelector = useSelector((state: RootState) => state.dynamic);
    const caseDetailsSelector = useSelector((state: RootState) => state.caseDetails);
    const label = props.data[1].required ? props.data[1].label + ' *' : props.data[1].label
    const emptyOptions: any = 'No Items are available based on selected parameters';
    const [errorDisabled, setErrorDisabled] = useState(props.data[1].required);
    const disableState = props.data[1].disabled ? props.data[1].disabled : props.isDisabled;

    const [expanded, setExpanded] = React.useState(undefined);
    const headerRef = React.useRef(null);

    const dispatch = useDispatch();
    const {
        showHelpText
    } = HelpText();

    useEffect(() => {
        if (props.data[1].required) {
            if (props.dataStore) {
                if (props.dataStore[props.data[0]] !== "") {
                    setErrorDisabled(false)
                }
                else {
                    setErrorDisabled(true)
                }
            }
        }
    }, [props.dataStore])


    const getKeyvalueData = (dataSet, selectkey) => {
        // let optionsList = props.optionsData[props.active_step][props.data[0]]
        let optionsList = props.optionsData[props.data[0]]
        let selectKeyValue = {}
        optionsList.forEach(element => {
            if (element.id === selectkey) {
                selectKeyValue['key'] = element.id
                selectKeyValue['value'] = element.value
            }
        });
        props.updateDispatchStore(dataSet, selectKeyValue);
    }

    const handleExpansion = id => (e) => {
        const newId = expanded === id ? undefined : id;
        if (e.type === 'keydown') {
            if (
                e.target.getAttribute('data-test') === 'header' && // check if keydown from header
                (e.key === ' ' || e.key === 'Enter')
            ) {
                setExpanded(newId);
            }
        }
        if (e.type === 'click') {
            setExpanded(newId);
        }
    };

    const loadKitContents = (rD) => {
        return (
            <ul style={{ width: '100%' }}>
                {
                    (rD.product_kits).map((kit, index) => (
                        <li style={{ listStyle: 'disc' }} key={index}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>
                                    {kit.product_kit_description}
                                    <br></br>{kit.product_kit_code}
                                </span>
                                <span style={{ marginLeft: '5%' }}>Quantity : {kit.product_kit_quantity}</span>
                            </div>
                        </li>
                    ))}
            </ul>
        )
    }

    const loadDevices = (rD) => {
        return (
            <ul>
                {rD.ruggedization && <li style={{ listStyle: 'disc' }}>Ruggedization: {rD.ruggedization}</li>}
                {rD.certification && <li style={{ listStyle: 'disc' }}>
                    Certification:
                    <ul>
                        lte-fdd:<li>{rD.certification.ce_certified && (rD.certification.ce_certified['lte-fdd']).length > 0 && (rD.certification.ce_certified['lte-fdd']).toString()}</li>
                        lte-tdd:<li>{rD.certification.ce_certified && (rD.certification.ce_certified['lte-tdd']).length > 0 && (rD.certification.ce_certified['lte-tdd']).toString()}</li>
                        nr:<li>{rD.certification.ce_certified && (rD.certification.ce_certified['nr']).length > 0 && (rD.certification.ce_certified['nr']).toString()}</li>
                    </ul>
                </li>}
                {rD.sim_type && <li style={{ listStyle: 'disc' }}>SIM type: {(rD.sim_type.data).toString()}</li>}
                {rD.wifi && <li style={{ listStyle: 'disc' }}>Wifi: {(rD.wifi.data).toString()}</li>}
                {rD.gps && <li style={{ listStyle: 'disc' }}>GPS: {(rD.gps.data).toString()}</li>}
                {rD.vpn_capability && <li style={{ listStyle: 'disc' }}>VPN Capability: {rD.vpn_capability} </li>}
                {rD.rbue && <li style={{ listStyle: 'disc' }}>RBUE Supported: {rD.rbue}</li>}
                {rD.nidm_iot && <li style={{ listStyle: 'disc' }}>NIDM-IOT Supported: {rD.nidm_iot}</li>}
                {rD.nidm_emm && <li style={{ listStyle: 'disc' }}>NIDM-EMM Supported: {rD.nidm_emm}</li>}
                {rD.ndt && <li style={{ listStyle: 'disc' }}>NDT Supported: {rD.ndt}</li>}
            </ul>
        )
    }

    const updateCheckbox = (event, device_name, product, product_list) => {
        let productcheck = { ...product }
        productcheck.checked = event.target.checked ? true : false
        let productListData = []
        product_list.map(item => {
            let productcheckdata = { ...item }
            productcheckdata.checked = false
            if (item.id === product.id) {
                productListData.push(productcheck)
            }
            else {
                productListData.push(productcheckdata)
            }
        })
        dispatch(setProp({ slice: "dynamic", key: `element.${props.active_step}.${[props.data[0]]}.${[device_name]}`, value: productListData }));
        props.updateRadioButton(props.data[0], device_name, product);

    }

    const selectedProduct = (device_name, product) => {
        let check = false;

        if (props.dataStore) {
            if (props.dataStore[props.data[0]]) {
                if (props.dataStore[props.data[0]][device_name]) {
                    let checkProduct = props.dataStore[props.data[0]][device_name].find((item) => item.checked && item.id === product.id ? item : null)
                    check = checkProduct ? true : false
                }
            }
        }
        return check
    }
    return (
        <>
            <div style={{ display: 'flex'}}>
                <TextInputLabelContent>
                    {label}
                </TextInputLabelContent>
                {showHelpText(props.data)}
            </div>
            {props.expandVisibility ? (
                <>
                    {props.optionsData && Object.entries(props.optionsData).length > 0 && props.optionsData && props.optionsData[props.data[0]] && Object.entries(props.optionsData[props.data[0]]).length === 0 ?  [] : Object.entries(props.optionsData).length > 0 && Object.entries(props.optionsData[props.data[0]]).map((data, _index) => { 
                        return (
                            props.optionsData[props.data[0]] && props.optionsData[props.data[0]][data[0]].length > 0 ?
                            <Fragment key={`react-fragment-expansion-${data[0]}_${_index}`}>
                                <div style={{ marginTop: '10px' }}>
                                    <TextInputLabelContent>
                                        {data[0]}
                                    </TextInputLabelContent>
                                </div>
                                <HorizontalDivider className='mb mt' />
                                <ExpansionPanelsContainer key={data[0]} style={{ marginBottom: '25px' }}>
                                    {props.optionsData[props.data[0]][data[0]].map(rD => (
                                        <ExpansionPanel
                                            expanded={expanded === rD.id}
                                            key={rD.id}
                                            id={rD.id}
                                            hoverEffect
                                            focusEffect
                                            mountContent={false}
                                        >
                                            <ExpansionPanelHeader
                                                data-test="header"
                                                ref={headerRef}
                                                role="button"
                                                aria-expanded={expanded === rD.id}
                                                style={{ justifyContent: 'space-between' }}

                                            >
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '25.75rem' }}>

                                                    <RadioButton
                                                        key={rD.id}
                                                        disabled={disableState}
                                                        onChange={(e) => {
                                                            if (e.type === "change") {
                                                                updateCheckbox(e, data[0], rD, props.dataStore ? props.dataStore[props.data[0]][data[0]] : data[1])
                                                                return
                                                            }
                                                        }}
                                                        checked={selectedProduct(data[0], rD)}
                                                    />
                                                    <RadioButtonLabelContent>
                                                        test
                                                    </RadioButtonLabelContent>

                                                    <Label style={{ marginLeft: "0px" }}>{rD.product_description}<br></br>{rD.product_code}</Label>
                                                </div>
                                                <ExpansionPanelButton
                                                    onClick={handleExpansion(rD.id)}
                                                    iconButtonProps={{
                                                        'aria-hidden': true,
                                                    }}
                                                />
                                                <label>{rD.list_price} {caseDetailsSelector.data.currency}</label>

                                            </ExpansionPanelHeader>

                                            <ExpansionPanelBody>
                                                <div style={{ display: 'flex', justifyContent: 'left', padding: PADDING }}>
                                                    <div style={{ width: 'auto' }}>
                                                        <p>{rD.title}</p>
                                                        {
                                                            rD ? (
                                                                rD['product_kits'] ? loadKitContents(rD) : loadDevices(rD)
                                                            )
                                                                : <></>
                                                        }
                                                    </div>
                                                </div>
                                            </ExpansionPanelBody>
                                        </ExpansionPanel>
                                    ))}
                                </ExpansionPanelsContainer>
                            </Fragment>: <Fragment key={`react-fragment-expansion-${data[0]}`}>{emptyOptions}</Fragment>
                        )
                    })}
                </>) : (
                <div
                    className='ml'
                    style={{
                        display: "flex",
                        // width: "50%"
                    }}>
                    {Object.entries(props.optionsData).length === 0 ? emptyOptions : props.optionsData[props.data[0]]?.map((radio, i) => {
                        return (
                            <Fragment key={`react-fragment-radio-${props.data[0]}_${i} `}>
                                <RadioButton
                                    key={i}
                                    disabled={disableState}
                                    onChange={(e) => {
                                        getKeyvalueData(props.data[0], radio.id)

                                    }}
                                    checked={props.dataStore && props.dataStore[props.data[0]] && props.dataStore[props.data[0]].key === radio.id}
                                />
                                <RadioButtonLabelContent>
                                    {radio.value}
                                </RadioButtonLabelContent>

                            </Fragment>
                        )
                    })
                    }

                </div>
            )}
            {(errorDisabled ? <>{props.errorMessageText(props.data[1].label)}</> : '')}
        </>
    )
}

export default RadioButtonField;