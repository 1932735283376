//@ts-nocheck
import _ from "lodash";
import { groupByActionTypes } from 'redux-undo';

export const findChild = (array: [], query: string | number, searchKey: string, inEachKey: string) => {
    let result;
    array.some(
        (child: any) =>
            (child[searchKey]?.toLowerCase().includes(query) && (result = child)) ||
            (result = findChild(child[inEachKey] || [], query, searchKey, inEachKey))
    );
    return result;
};

export const iterate = (instance, f) => {
    if (instance.hasOwnProperty('extensionData') && Object.keys(instance.extensionData).length > 0) {
        for (let child of instance.extensionData) {
            f(child);
            iterate(child, f)
        }
    }
}

export const generateSyncOfferItems = (type, arr, contractDuration,dynamicSelector): [] => {
    if (arr.length === 0) {
        return ""
    }
    switch (type) {
        case 'ndac':
            //Array of Products schema
            return _.compact(arr.map((e) => {
                // if service is not to be shown in deployment - OOT, and other products in boq add here
                if (!e.oot_displaySection || (e.oot_displaySection && e.oot_displaySection !== 'deployment_technical')) {
                    let prodType = e.type;
                    if(!prodType && e.deviceProductID === 'SLA' && dynamicSelector.data.reference_solution.commercial_model === "operating_lease") {
                        prodType = "subscription";
                    }
                    return new Object({ "product_code": e.product_id ? e.product_id : e.productCode, "quantity": e.quantity, "type": prodType })
                }
            }));
        case 'mpw':
            return [];
        case 'services':
            return _.compact(arr.flatMap((e) => {
                // if service is to be shown in deployment then add in services array
                // modified quantity and duration to send 1 , because we are sending calculated price, which shudnt get again multiplied in oot
                if (e.oot_displaySection && e.oot_displaySection === 'deployment_technical') {
                    if ((e.hasOwnProperty('ootId') || e.ootId)) {
                        const serviceid = (e.hasOwnProperty('ootId') || e.ootId) ? e.ootId : e.product_id;
                        return serviceid ? new Object({ "item_code": `${e.ootId}-${e.product_name}`, "price": e.cost, "quantity": 1, "duration": 1 }) : [];
                    } else {
                        return e.work_package ? new Object({ "item_code": `${e.work_package}`, "price": e.cost, "quantity": 1, "duration": 1 }) : [];
                    }
                }
            }));
    }
}
export const getEmptyOOTfields = (oot_response) => {
    const res = Object.keys({ ...(_.pickBy(oot_response, _.isEmpty)), ...(_.pickBy(oot_response, { "default": "" })) })
    return res;
}

export default function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}
export function getTokenExpiryTime(token) {
    try {
        const decodedToken = parseJwt(token); // Decoding the token
        const expiryTime = decodedToken.exp * 1000; // Multiplying by 1000 to convert from seconds to milliseconds
        const currentTime = Date.now(); // Getting the current timestamp in milliseconds

        const timeRemaining = expiryTime - currentTime;
        if (timeRemaining < 0) {
            return -1;
        } else {
            const seconds = Math.floor((timeRemaining / 1000) % 60);
            const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
            const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
            const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));

            let timeString = "";
            if (days > 0) {
                timeString += days + "d ";
            }
            if (hours > 0) {
                timeString += hours + "h ";
            }
            if (minutes > 0) {
                timeString += minutes + "m ";
            }
            if (seconds > 0) {
                timeString += seconds + "s";
            }

            // if (minutes === onTimer.time) {
            //   onTimer.exec()
            // }

            return timeString.trim();
        }
    } catch (error) {
        console.error("Invalid token");
        return null;
    }
}

export function hiddenLogin(src) {
    const doLogin = new Promise((rs, rj) => {
        // Step 1: Create an iframe dynamically or include it in your HTML
        const iframe = document.createElement("iframe");

        // Step 2: Set the display style property to "none" to hide the iframe
        iframe.style.display = "none";

        // Step 3: Set the src attribute of the iframe
        iframe.src = src; // Replace with your desired URL

        // Step 4: Listen for the "load" event on the iframe
        iframe.addEventListener("load", function () {
            // Step 5: Retrieve the URL of the iframe
            const iframeURL = iframe.contentWindow.location.href;
            const queryString = iframeURL;
            const urlParams = new URLSearchParams(queryString.split('#')[1]);
            if (urlParams.get('id_token')) {
                rs({
                    response_code: 1,
                    url: iframeURL,
                    id_token: urlParams.get('id_token'),
                    access_token: urlParams.get('access_token'),
                })
            } else {
                rs({
                    response_code: 0,
                    error: urlParams.get('error'),
                    error_description: urlParams.get('error_description')
                });
            }

        });

        // Append the iframe to the document body or any desired container
        document.body.appendChild(iframe);
    })
    return doLogin;

}
export const mustache = (string, data = {}) => Object.entries(data).reduce((res, [key, value]) => res.replace(new RegExp(`{{\\s*${key}\\s*}}`, "g"), value), string);

export function unmustache(text) {
    let regex = /[^\W_]+|{{(.*?)}}|\d+/g;

    let results = regex.exec(text);

    let array = [];

    while (results !== null) {
        if (results[1]) {
            array.push(results[1]);
        } else {
            array.push(results[0]);
        }
        results = regex.exec(text);
    }

    return array.filter((e) => !Number.isInteger(parseInt(e)));
}
export const roundCount = (number: string | number, range = 2) => Number(parseFloat(Number(number).toFixed(range)).toFixed(range));

export const occurrencesOf = (number, numbers: []): number => numbers.reduce((counter, currentNumber) => (number === currentNumber ? counter + 1 : counter), 0);

export const serializeFunction = (func) => func.toString();
export const deserializeFunction = (funcString) => (new Function(`return ${funcString}`)());

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1).replace(/[^a-zA-Z ]/g, " ");


export const updateExtensionProduct = (updatedCartItems, product, method = "+", parentSync = false) => {

    if (product.hasOwnProperty('extensionData')) {

        const extensionItems = product.extensionData;

        extensionItems.forEach(extensionItem => {
            const extensionItemQuantity = (parentSync) ? roundCount(product.quantity) : extensionItem.quantity;
            const existingExtensionIndex = _.findIndex(updatedCartItems, {
                product_id: extensionItem.product_id,
                cui_type: "extension"
            });

            if (existingExtensionIndex !== -1) {
                if (method === "-") {
                    if (product.quantity === 0 || product.quantity < extensionItemQuantity) {
                        updatedCartItems.splice(existingExtensionIndex, 1);
                    } else {
                        const updatedExtensionItem = {
                            ...extensionItem,
                            quantity: updatedCartItems[existingExtensionIndex].quantity - extensionItemQuantity,
                            cost: updatedCartItems[existingExtensionIndex].quantity * roundCount(extensionItem.list_price),
                            cui_type: "extension"
                        };
                        updatedCartItems[existingExtensionIndex] = updatedExtensionItem;
                    }
                } else {
                    const prodQty = updatedCartItems[existingExtensionIndex].quantity + 1;

                    const updatedExtensionItem = {
                        ...extensionItem,
                        quantity: updatedCartItems[existingExtensionIndex].quantity + extensionItemQuantity,
                        cost: updatedCartItems[existingExtensionIndex].quantity * roundCount(extensionItem.list_price),
                        cui_type: "extension"
                    };
                    updatedCartItems[existingExtensionIndex] = updatedExtensionItem;
                }
            } else {
                if (method === "+" && product.quantity > 0) {
                    updatedCartItems.push({
                        ...extensionItem,
                        quantity: extensionItemQuantity,
                        cost: extensionItemQuantity * roundCount(extensionItem.list_price),
                        cui_type: "extension"
                    });
                }
            }
        });
    }
};
export const updateExistingProduct = (cartItems, product, method = "+", parentSync = false, unWrap = true) => {
    // Convert the quantity to number
    const updatedCartItems = [...cartItems].map(item => ({
        ...item,
        quantity: +item.quantity // convert to number
    }));

    const existingProductIndex = updatedCartItems.findIndex(
        prod => prod.product_id === product.product_id && prod.type === product.type
    );

    if (existingProductIndex !== -1) {
        const existingProduct = updatedCartItems[existingProductIndex];
        const typeGroupProducts = updatedCartItems.filter(
            prod => prod.product_id === existingProduct.product_id && prod.type == existingProduct.type && prod.type != "spares"
        );

        const updatedTypeGroup = [existingProduct, ...typeGroupProducts].map(prod => ({
            ...prod,
            quantity: method === "-" ? parseInt(prod.quantity,10) - parseInt(product.quantity,10) : parseInt(prod.quantity,10) + parseInt(product.quantity,10),
            cost: (method === "-" ? parseInt(prod.quantity,10) - parseInt(product.quantity,10) : parseInt(prod.quantity,10) + parseInt(product.quantity,10)) * roundCount(prod.list_price),
        }));

        if (method === "-" && updatedTypeGroup.some(prod => parseInt(prod.quantity,10) <= 0)) {
            updatedCartItems.splice(existingProductIndex, 1);
        } else {
            updatedTypeGroup.forEach(updatedProduct => {
                const index = updatedCartItems.findIndex(
                    prod => prod.product_id === updatedProduct.product_id && prod.type === updatedProduct.type
                );
                updatedCartItems[index] = updatedProduct;
            });
        }
        let uCI = updatedCartItems.filter((e) => e.quantity !== 0);
        if (!unWrap) {
            return uCI;
        }
        // Update extension products
        if (product.hasOwnProperty('extensionData')) {
            updateExtensionProduct(updatedCartItems, product, method, parentSync);
        }
        uCI = updatedCartItems.filter((e) => e.quantity !== 0);
        let uSP = updateSparesProduct(uCI, product, method)
        return uSP.items;
    } else {
        const quantity = product.hasOwnProperty('quantity') ? product.quantity : 1;
        const newProduct = {
            ...product,
            quantity,
            cost: product.cui_cost ? (quantity * product.cui_cost) : quantity * roundCount(product.list_price),
        };
        //Push only if + method
        if (method == '+') {
            updatedCartItems.push(newProduct);
        }
        let uCI = updatedCartItems.filter((e) => e.quantity !== 0);
        //Feature:1 if unWrap is set-to-true  allow below executions else stop.
        if (!unWrap) {
            return uCI;
        }
        //Feature:2 Update extension products
        if (product.hasOwnProperty('extensionData')) {
            //Feature:4 if parentSync-true, set the extensionData same as parentQty.
            updateExtensionProduct(updatedCartItems, newProduct, method, parentSync);
        }
        uCI = updatedCartItems.filter((e) => e.quantity !== 0);
        //Feature:3 Update spare products
        let uSP = updateSparesProduct(uCI, newProduct, method)
        return uSP.items;
    }
}

export const updateSparesProduct = (cartItems, product, method = "+") => {
    let report = { min: 1, update_spare: false, spare: {}, items: [] }
    report.items = [...cartItems];
    //Rule:Check if the product exists , then only examins
    let results = cartItems.filter((e) => e.product_id == product.product_id || e.cui_product_id === product.product_id);
    if (results.length !== 2) {
        return report;
    }
    const [alpha, beta] = results;
    //Examine which is Parent and which is child from the Boq
    const labReport = { parent: {}, child: {} };
    results.forEach((product) => {
        switch (product.type) {
            case 'spares':
            case 'cui_spares':
                //Triggered: When Child item is mutated
                labReport.child = product
                break;
            case 'product':
                //Triggered: When Parent spare is mutated
                labReport.parent = product
                break;

            default:
                break;
        }
    });
    if (Object.values(labReport).some((e) => Object.keys(e).length == 0)) {
        return report;
    }
    //Test btw p1 & sp1 Execution.
    let p1 = labReport.parent;
    let sp1 = labReport.child;
    const condition = (method == "+") ? Math.ceil((p1.quantity * 0.1)) > sp1.quantity : Math.ceil((p1.quantity * 0.1)) < sp1.quantity;
    if (product.type == "spares") {
        //Set the MIN
        report.min = 1;
        //calculate total no of spares



    } else {
        // Set the Quantity
        // const condition = (method == "+") ? Math.ceil((p1.quantity * 0.1)) > sp1.quantity : Math.ceil((p1.quantity * 0.1)) < sp1.quantity;
        if (condition) {
            sp1 = { ...sp1 };
            // Change the Qty & Set the MIN
            report.update_spare = true;
            sp1.quantity = Math.ceil((p1.quantity * 0.1));
            sp1.cost = Math.ceil((p1.quantity * 0.1)) * roundCount(p1.list_price);
            report.spare = (sp1);
            report.min = Math.ceil((p1.quantity * 0.1));
        } else {
            // No change with Qty
            report.update_spare = false;
            report.spare = (sp1);
            report.min = sp1.quantity;
        }
        report.items = _.map(cartItems, (item) => {
            if (item.product_id == sp1.cui_product_id && item.type == "spares") {
                return {
                    ...item,
                    cui_min: report.min,
                    quantity: report?.spare?.quantity ? report?.spare?.quantity : report.min,
                }; // Update the 'age' property
            }
            return item;
        })

    }

    return report;
}

export const extractBand = (title: string) => {
    const productName = title;
    const bandsRegex = /[B]\d+|\d+[ab]/gi;
    const bands = productName.match(bandsRegex);
    if (bands) {
        return bands[0];

    } else {
        return false;
    }
}

export const batchGroupBy = {
    _group: null, // Current group ID

    start(group: any = crypto.randomUUID()) {
        this._group = group; // Start a new group with a unique ID
    },

    end() {
        this._group = null; // End the current group
    },

    init(rawActions) {
        const defaultGroupBy = groupByActionTypes(rawActions);

        // Return a function that determines the group for each action
        return (action) => this._group || defaultGroupBy(action);
    }
};

export const patchDurationItems = (cartItems, fillers = { contract_duration: 0, sla_duration: 0, warranty_duration: 0 }) => {
    return cartItems.map(e => {
        if (e.category !== "warranty" && e.type === "subscription") {
            /*Subsciption,License: Opex - month =>selected   , capex - year =>5 */
            return new Object({ ...e, cost: roundCount(e.list_price) * e.quantity * fillers.contract_duration })
        } else if (e.category === "sla") {
            /* irrecpectoive of opexSLA => months=>3*12ext - warranty =>year4 */
            return new Object({ ...e, cost: roundCount(e.list_price) * e.quantity * fillers.sla_duration })
        } else if (e.category === "warranty") {
            return new Object({ ...e, cost: roundCount(e.list_price) * e.quantity * fillers.warranty_duration })
        } else {
            return e;
        }
    });
}

export const convertNumberToString = (input) => {
    if (typeof input === 'number') {
        return input.toString(); // Convert number to string
    } else if (typeof input === 'string') {
        return input; // Return the string itself
    } else {
        // For other types like objects, arrays, etc.
        return "Invalid input. Expected a number or a string.";
    }
}



export function getRemainingSteps(currentStep, steps) {
    const allSteps = Object.keys(steps);
    const currentIndex = allSteps.indexOf(currentStep);

    if (currentIndex === -1 || currentIndex === allSteps.length - 1) {
        return []; // If the current step is not found or it's the last step, return an empty array
    }

    return allSteps.slice(currentIndex);
}

/** Function to convert id string to Label */
export function convertIdToLabel(str) {
    // Replace underscores, hyphens, and dots with spaces
    // Feel free to add more delimiters in the regX
    const cleanedStr = str.replace(/[_\-\.]/g, ' ');

    // Capitalize the first letter of each word
    const words = cleanedStr.split(' ');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

    // Join the words with spaces
    const label = capitalizedWords.join(' ');
    return label;
}