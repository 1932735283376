import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "Pages/Home";
import { Login } from "Pages/Login";
import { Segment } from "Pages/Segment";
import NotFound from "./NotFound";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/segment" element={<Segment />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
