import CartBoq from 'Components/Content/Cart/CartBoq'
import React from 'react'

const View = () => {
  return (
      <>
      <CartBoq/>
      </>
  )
}

export default View