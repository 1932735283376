
import React from "react";
import _ from 'lodash';
import { Tooltip } from "@nokia-csf-uxr/ccfk";
import { LabelHelpIcon } from "@nokia-csf-uxr/ccfk/Label";
import DOMPurify from 'dompurify';


/**
 * Sanitizes the provided HTML string to remove any potentially dangerous elements.
 *
 * @param {string} html - The HTML string to be sanitized.
 * @returns {string} - The sanitized HTML string with only allowed tags.
 */
const getSanitizedHtml = (html) => {
  //sanitize the html not to allow dangerous elements
  return DOMPurify.sanitize(html,{ALLOWED_TAGS: []});
};

/**
 * Generates sanitized help text from the provided data.
 *
 * This function extracts specific fields from the data, sanitizes their HTML content
 * to remove any potentially dangerous elements, and then compacts and joins the sanitized
 * fields into a single help text string.
 *
 * @param {Object} data - The data object containing fields to be sanitized.
 * @param {string} data.description - The description field to be sanitized.
 * @param {string} data.help_title - The help title field to be sanitized.
 * @param {string} data.help_text - The help text field to be sanitized.
 * @returns {string} - The sanitized help text string.
 */

const displayHelpText = (data) => {
  const fieldsToSanitize = ['description', 'help_title', 'help_text'];
  const sanitizedFields = fieldsToSanitize.map(field => (data[1][field] && data[1][field]) ? getSanitizedHtml(data[1][field]) : null);
  const helpText = _.compact(sanitizedFields).join(" , ");
  return helpText || "";
};

export const HelpText = () => {
  const showHelpText = (data) => {
    const helpText = displayHelpText(data);
    return (
      <>
        {helpText && helpText.length > 0 && (
          <Tooltip placement="top" trigger="hover" tooltip={helpText}>
            <LabelHelpIcon />
          </Tooltip>
        )}
      </>
    );
  };

  return {
    showHelpText
  }
}