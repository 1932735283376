import React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setValidationMessages } from "Store/general/auth";

export const useShowValidateMessages = () => {
  const dispatch = useDispatch();

  const showValidateMessages = (validateDetails) => {
    const warnings = [];
    const info = [];
    const errors = [];
    let nextDisabled = undefined;
    let dispatchFlags = [];

    validateDetails.forEach((detail) => {
      if (detail.type === "warning") {
        warnings.push(detail.message);
      } else if (detail.type === "information") {
        info.push(detail.message);
      } else {
        errors.push(detail.message);
      }
    });

    const warningData = displayMessages(warnings);
    dispatchFlags.push(dispatchMessage(warnings, warningData,'warn'));

    const infoData = displayMessages(info);
    dispatchFlags.push(dispatchMessage(info, infoData,'info'));

    const errorData = displayMessages(errors);
    dispatchFlags.push(dispatchMessage(errors, errorData,'error'));

    if(errors.length > 0) {
      nextDisabled = true;
    }
    if(dispatchFlags.every((flag) => flag)) {
      return nextDisabled
    }
    // return nextDisabled;
  };

  const displayMessages = (messages) => {
    const listItems = messages.map((message, index) => (
      <li key={index}>{message}</li>
    ));

    return (
      <ul>
        {listItems}
      </ul>
    );
  }

  const dispatchMessage = (messages, data, type) => {
    messages.length > 0 && dispatch(
      setValidationMessages({
        key: type,
        value: {
          errorMsg: data,
        },
      })
    );
    return true;
  }

  return {showValidateMessages};
}
