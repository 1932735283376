/**
 * ErrorFallback component to display a fallback UI when an error occurs.
 *
 * @param {Object} props - The component props.
 * @param {Error} props.error - The error object that triggered the fallback.
 * @returns {JSX.Element} The rendered fallback UI.
 */
import React from "react";
import PropTypes from "prop-types";
import { EmptyState } from "Components/Content/EmptyState";
import Header from "Components/Header";
import { App } from "@nokia-csf-uxr/ccfk";

const ErrorFallback = ({ error }) => {
  console.log("error", error);
  return (
    <>
      <App disableAnimation={false}>
        <Header />
        <div
          style={{ height: "100vh",padding: "5rem 0", display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <EmptyState
            title="Something went wrong"
            msg={
              "Sorry, the page you are looking for cannot be displayed, Please refresh the page or come back later."
            }
            src="/assets/images/something-went-wrong.svg"
            actionText="Go to landing page"
            actionhref={() => window.location.reload()}
          />
        </div>
      </App>
    </>
  );
};

ErrorFallback.propTypes = {
  error: PropTypes.object,
};
export default ErrorFallback;
